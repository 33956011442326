@import '../../../../styles/app/common/variables.scss';

.incidentDetailSettingsAction {
    svg path {
        color: white;
    }

    position: absolute;
    top: 0;
    right: 0;
}

.fullScreenFormWrapper {
    .expansionControl.fullScreenFormCollapser {
        display: None;
    }
}

.fullScreenFormWrapper.fullScreen {
    .expansionControl.fullScreenFormExpander {
        display: None;
    }

    .expansionControl.fullScreenFormCollapser {
        display: inline-block;
    }
}

.formWithComments {

    display: grid;
    position: relative;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-gap: 20px;

    .autoField-input-wrapper svg {
        width: 1rem;
        height: auto;
        margin-left: 0.5rem;
    }

    .formSection {
        grid-row: 1;

        // max-height: 70vh;
        // overflow-y: scroll;
        // overflow-x: clip;


        .viewSettings {
            display: flex;
            margin-top: 1rem;
            width: 96%;
            justify-content: space-between;
            position: relative;
            z-index: 1;

            .viewSettings-left {
                display: flex;

                .expansionControl {
                    margin-left: 1rem;
                    margin-top: -4px;

                    .MuiIconButton-root {
                        padding: 4px;
                    }
                }
            }

            &.lower {
                justify-content: end;
            }

            padding: 0rem;
            padding-left: 4rem; // this is currently adjusted to align with paper elevation wrapped form elements
            padding-right: 3rem; // this is currently adjusted to align with paper elevation wrapped form elements
            border-bottom: 1px solid $gray-lightest;
            box-sizing: border-box;

            .MuiFormControlLabel-root {
                margin-left: 0.25rem;
                margin-right: 0.25rem;
                margin-bottom: 0.2rem;
            }

            svg.flagged {
                color: red;
                width: 1rem;
                height: auto;
            }
        }

        @media screen {
            .generalActionFormWrapper {
                max-height: 70vh;
                overflow-y: scroll;
                overflow-x: clip;
            }
        }

    }

    .commentsSection {
        grid-row: 1;
        min-width: 300px;
    }

    &.commentsOpen {
        @media screen {
            .formSection {
                .postComponentSelected {
                    background-color: $gray-lightest;
                    padding: 20px;
                }
            }
        }
    }

    &.commentsOpen {
        .formSection {
            grid-column: span 9;
        }

        .commentsSection {
            grid-column: span 3;
        }
    }

    &.commentsClosed {
        .formSection {
            grid-column: span 12;
        }
    }
}

.generalActionFormWrapper {

    .generalActionFormGrid.HASIncidentDetailForm {

        &.fullInvestigation {

            .incidentType {
                .input-wrapper--incident_type {
                    grid-column: span 6;
                    grid-row: 2;
                }
            }

            .apDetails {
                .input-wrapper--ap_name {
                    grid-column: span 6;
                    grid-row: 2;
                }

                .input-wrapper--ap_address {
                    grid-column: span 6;
                    grid-row: 3;
                }

                .input-wrapper--ap_contact_number {
                    grid-column: span 6;
                    grid-row: 3;
                }

                .input-wrapper--ap_employment_status {
                    grid-column: span 6;
                    grid-row: 4;
                }

                .input-wrapper--ap_job_title {
                    grid-column: span 6;
                    grid-row: 4;
                }

                .input-wrapper--ap_email {
                    grid-column: span 6;
                    grid-row: 5;
                }

                .input-wrapper--ap_length_of_service {
                    grid-column: span 4;
                    grid-row: 5;
                }

            }

            .recordOfIncident {

                .input-wrapper--occurred_at {
                    grid-column: span 6;
                    grid-row: 2;
                }

                .input-wrapper--location {
                    grid-column: span 6;
                    grid-row: 2;
                }

                .input-wrapper--incident_description {
                    grid-column: span 12;
                    grid-row: 3;
                }

                .input-wrapper--activity_description {
                    grid-column: span 12;
                    grid-row: 4;
                }

                .input-wrapper--reported_to {
                    grid-column: span 6;
                    grid-row: 5;
                }

                .input-wrapper--reported_at {
                    grid-column: span 6;
                    grid-row: 5;
                }
            }

            .injuryDetails {

                // details of injury
                .input-wrapper--injury_details {
                    grid-column: span 12;
                    grid-row: 2;
                }

                .input-wrapper--injury_body_parts {
                    grid-column: span 12;
                    grid-row: 3;
                }

                .input-wrapper--injury_diagram {
                    grid-column: span 6;
                    grid-row: 4;

                    .caption {
                        margin-right: 4rem;
                    }

                    .imgValue {
                        padding: 1rem;
                        margin-bottom: 2rem;
                        border: 1px solid $gray-lighter;
                    }

                    &.printable {
                        .imgValue {
                            padding: 0;
                            margin-bottom: unset;
                            border: none;
                        }
                    }

                    .fileInputControl {
                        margin-top: 1rem;
                    }
                }

            }

            .immediateActionsTaken {
                .input-wrapper--first_aid_given {
                    grid-column: span 4;
                    grid-row: 2;
                }

                .input-wrapper--first_aider_name {
                    grid-column: span 8;
                    grid-row: 2;
                }

                .input-wrapper--first_aid_details {
                    grid-column: span 12;
                    grid-row: 3;
                }

                .input-wrapper--has_ap_been_seen_by_a_medical_professional {
                    grid-column: span 4;
                    grid-row: 4;
                }

                .input-wrapper--ambulance_in_attendance {
                    grid-column: span 4;
                    grid-row: 4;
                }

                .input-wrapper--hospital_visit_required {
                    grid-column: span 4;
                    grid-row: 4;
                }

                .input-wrapper--authorities_involved {
                    grid-column: span 12;
                    grid-row: 5;
                }
            }

            .actionsTaken {

                .input-wrapper--area_closed_off_or_restricted {
                    grid-column: span 4;
                    grid-row: 2;
                }

                .input-wrapper--work_prohibited {
                    grid-column: span 4;
                    grid-row: 2;
                }

                .input-wrapper--equipment_out_of_service_and_secured {
                    grid-column: span 4;
                    grid-row: 2;
                }

                .input-wrapper--interim_safety_procedures_implemented {
                    grid-column: span 4;
                    grid-row: 3;
                }

                .input-wrapper--working_practices_changed_and_staff_trained {
                    grid-column: span 4;
                    grid-row: 3;
                }

                .input-wrapper--equipment_examined_by_competent_person {
                    grid-column: span 4;
                    grid-row: 3;
                }

            }

            .absenceManagement {
                .input-wrapper--afterwards {
                    grid-column: span 4;
                    grid-row: 2;
                }

                .input-wrapper--afterwards_other {
                    grid-column: span 8;
                    grid-row: 2;
                }

                .input-wrapper--sick_leave_start {
                    grid-column: span 4;
                    grid-row: 3;
                }

                .input-wrapper--return_to_work_date {
                    grid-column: span 4;
                    grid-row: 3;
                }
            }

            .amsm {
                .location {
                    .input-wrapper--access_and_egress_used {
                        grid-column: span 4;
                        grid-row: 2;
                    }

                    .input-wrapper--housekeeping_satisfactory {
                        grid-column: span 4;
                        grid-row: 2;
                    }

                    .input-wrapper--floor_conditions {
                        grid-column: span 4;
                        grid-row: 2;
                    }

                    .input-wrapper--lighing_adequate {
                        grid-column: span 4;
                        grid-row: 3;
                    }

                    .input-wrapper--noise_levels {
                        grid-column: span 4;
                        grid-row: 3;
                    }

                    .input-wrapper--weather_conditions {
                        grid-column: span 4;
                        grid-row: 3;
                    }

                    .input-wrapper--fall_from_unguarded {
                        grid-column: span 4;
                        grid-row: 4;
                    }

                    .input-wrapper--segregation_measures {
                        grid-column: span 4;
                        grid-row: 4;
                    }

                    .input-wrapper--appropriate_warning_signs {
                        grid-column: span 4;
                        grid-row: 4;
                    }

                    .input-wrapper--adequate_information {
                        grid-column: span 4;
                        grid-row: 5;
                    }
                }

                .plantAndEquipment {
                    .input-wrapper--correct_equipment {
                        grid-column: span 4;
                        grid-row: 2;
                    }

                    .input-wrapper--equipment_working_order {
                        grid-column: span 4;
                        grid-row: 2;
                    }

                    .input-wrapper--equipment_properly_used {
                        grid-column: span 4;
                        grid-row: 2;
                    }

                    .input-wrapper--equipment_guards_adequate {
                        grid-column: span 4;
                        grid-row: 3;
                    }

                    .input-wrapper--equipment_protective_devices_working {
                        grid-column: span 4;
                        grid-row: 3;
                    }

                    .input-wrapper--equipment_maintenance_records_up_to_date {
                        grid-column: span 4;
                        grid-row: 3;
                    }

                    .input-wrapper--equipment_control_layout_ok {
                        grid-column: span 4;
                        grid-row: 4;
                    }

                    .input-wrapper--correct_materials_and_substances {
                        grid-column: span 4;
                        grid-row: 4;
                    }
                }

                .processOrProcedure {
                    .input-wrapper--safe_working_procedures {
                        grid-column: span 4;
                        grid-row: 2;
                    }

                    .input-wrapper--safe_working_procedures_up_to_date {
                        grid-column: span 4;
                        grid-row: 2;
                    }

                    .input-wrapper--safe_working_procedures_raa {
                        grid-column: span 4;
                        grid-row: 2;
                    }

                    .input-wrapper--safe_working_procedures_relevant {
                        grid-column: span 4;
                        grid-row: 3;
                    }

                    .input-wrapper--safe_working_procedures_on_site {
                        grid-column: span 4;
                        grid-row: 3;
                    }

                    .input-wrapper--safe_working_procedures_followed {
                        grid-column: span 4;
                        grid-row: 3;
                    }

                    .input-wrapper--ap_inducted {
                        grid-column: span 4;
                        grid-row: 4;
                    }
                }

                .thePersonInvolved {
                    .input-wrapper--ap_physically_suitable {
                        grid-column: span 4;
                        grid-row: 2;
                    }

                    .input-wrapper--ap_competent {
                        grid-column: span 4;
                        grid-row: 2;
                    }

                    .input-wrapper--ap_trained {
                        grid-column: span 4;
                        grid-row: 2;
                    }

                    .input-wrapper--work_safe_without_ppe {
                        grid-column: span 4;
                        grid-row: 3;
                    }

                    .input-wrapper--ap_wearing_correct_ppe {
                        grid-column: span 4;
                        grid-row: 3;
                    }

                    .input-wrapper--human_error {
                        grid-column: span 4;
                        grid-row: 3;
                    }

                    .input-wrapper--horseplay {
                        grid-column: span 4;
                        grid-row: 4;
                    }

                    .input-wrapper--violation {
                        grid-column: span 4;
                        grid-row: 4;
                    }

                    .input-wrapper--contributory_factors {
                        grid-column: span 4;
                        grid-row: 4;
                    }
                }
            }

            .accidentCause {
                .input-wrapper--cause {
                    grid-column: span 12;
                    grid-row: 2
                }

                .input-wrapper--how_it_could_have_been_prevented {
                    grid-column: span 12;
                    grid-row: 3;
                }
            }

            .HAndSUseOnly {
                .input-wrapper--h_and_s_department_comments {
                    grid-column: span 12;
                    grid-row: 2
                }

                // .input-wrapper--status { //currently managed in form settings panel
                //     grid-column: 9/ span 4;
                //     grid-row: 5;
                //     background-color: rgb(248, 242, 242);
                //     padding: 1rem;
                // }


            }

            .submission {
                .input-wrapper--investigation_submission_statement_made {
                    grid-column: span 12;
                    grid-row: 2
                }

                // .input-wrapper--investigation_submitted is a hidden field
                .input-wrapper--investigation_completed_by {
                    grid-column: span 6;
                    grid-row: 3
                }

                .input-wrapper--investigation_completed_by_job_title {
                    grid-column: span 6;
                    grid-row: 3
                }
            }

            // further actions required
            .input-wrapper--site_accident_book_submitted {
                grid-column: span 4;
                grid-row: 2;
            }

            .input-wrapper--cctv_available {
                grid-column: span 4;
                grid-row: 2;
            }

            .input-wrapper--witness_statements_gathered {
                grid-column: span 4;
                grid-row: 2;
            }

            // confidentiality requested
            .input-wrapper--confidential {
                grid-column: span 4;
                grid-row: 11;
            }
        }

        // new stuff

        &.initialAccidentInfo {

            .apDetails {
                .input-wrapper--ap_name {
                    grid-column: span 6;
                    grid-row: 2;
                }

                .input-wrapper--ap_job_title {
                    grid-column: span 6;
                    grid-row: 2;
                }

                .input-wrapper--ap_email {
                    grid-column: span 6;
                    grid-row: 3;
                }
            }

            .recordOfIncident {

                .input-wrapper--occurred_at {
                    grid-column: span 6;
                    grid-row: 2;
                }

                .input-wrapper--location {
                    grid-column: span 6;
                    grid-row: 2;
                }

                .input-wrapper--incident_description {
                    grid-column: span 12;
                    grid-row: 3;
                }

                .input-wrapper--reported_to {
                    grid-column: span 6;
                    grid-row: 4;
                }

                .input-wrapper--reported_at {
                    grid-column: span 6;
                    grid-row: 4;
                }
            }

            .injuryDetails {

                // details of injury
                .input-wrapper--injury_details {
                    grid-column: span 12;
                    grid-row: 2;
                }

                .input-wrapper--injury_body_parts {
                    grid-column: span 12;
                    grid-row: 3;
                }
            }

            .immediateActionsTaken {
                .input-wrapper--first_aid_given {
                    grid-column: span 4;
                    grid-row: 2;
                }

                .input-wrapper--ambulance_in_attendance {
                    grid-column: span 4;
                    grid-row: 2;
                }

                .input-wrapper--hospital_visit_required {
                    grid-column: span 4;
                    grid-row: 2;
                }

                .input-wrapper--area_closed_off_or_restricted {
                    grid-column: span 4;
                    grid-row: 3;
                }

                .input-wrapper--work_prohibited {
                    grid-column: span 4;
                    grid-row: 3;
                }

                .input-wrapper--equipment_out_of_service_and_secured {
                    grid-column: span 4;
                    grid-row: 3;
                }

                .input-wrapper--interim_safety_procedures_implemented {
                    grid-column: span 4;
                    grid-row: 4;
                }

                .input-wrapper--working_practices_changed_and_staff_trained {
                    grid-column: span 4;
                    grid-row: 4;
                }

                .input-wrapper--equipment_examined_by_competent_person {
                    grid-column: span 4;
                    grid-row: 4;
                }
            }

            .furtherActionsRequired {

                // further actions required
                .input-wrapper--site_accident_book_submitted {
                    grid-column: span 4;
                    grid-row: 2;
                }

                .input-wrapper--cctv_available {
                    grid-column: span 4;
                    grid-row: 2;
                }

                .input-wrapper--witness_statements_gathered {
                    grid-column: span 4;
                    grid-row: 2;
                }

                // confidentiality requested
                .input-wrapper--confidential {
                    grid-column: span 4;
                    grid-row: 3;
                }
            }

            .HAndSUseOnly {
                .input-wrapper--h_and_s_department_comments {
                    grid-column: span 12;
                    grid-row: 2
                }
            }



        }

        &.riddor {

            .submissionReference {
                .input-wrapper--reference {
                    grid-column: span 6;
                    grid-row: 2;
                }
            }

            .companyDetails {
                .input-wrapper--company {
                    grid-column: span 6;
                    grid-row: 2;
                }

                .input-wrapper--point_of_contact_and_job_title {
                    grid-column: span 6;
                    grid-row: 2;
                }

                .input-wrapper--poc_telephone_numbers {
                    grid-column: span 6;
                    grid-row: 3;
                }

                .input-wrapper--poc_email_address {
                    grid-column: span 6;
                    grid-row: 3;
                }
            }

            .accidentLocationDetails {

                .input-wrapper--accident__location {
                    grid-column: span 6;
                    grid-row: 2;
                }

                .input-wrapper--organisation_name {
                    grid-column: span 6;
                    grid-row: 2;
                }

                .input-wrapper--location_poc {
                    grid-column: span 12;
                    grid-row: 3;
                }

                .input-wrapper--local_authority {
                    grid-column: span 6;
                    grid-row: 4;
                }

                .input-wrapper--department_or_location_on_premise {
                    grid-column: span 6;
                    grid-row: 4;
                }

                .input-wrapper--type_of_work {
                    grid-column: span 6;
                    grid-row: 5;
                }
            }

            .accidentDetails {

                .input-wrapper--accident__occurred_at {
                    grid-column: span 6;
                    grid-row: 2;
                }

                .input-wrapper--type_of_accident {
                    grid-column: span 6;
                    grid-row: 2;
                }

                .input-wrapper--work_process_involved {
                    grid-column: span 12;
                    grid-row: 3;
                }

                .input-wrapper--main_factor_involved {
                    grid-column: span 6;
                    grid-row: 4;
                }

                .input-wrapper--what_happened {
                    grid-column: span 6;
                    grid-row: 4;
                }
            }

            .injuredPersonDetails {

                // details of injury
                .input-wrapper--accident__ap_name {
                    grid-column: span 6;
                    grid-row: 2;
                }

                .input-wrapper--accident__ap_address {
                    grid-column: span 12;
                    grid-row: 3;
                }

                .input-wrapper--accident__ap_contact_number {
                    grid-column: span 6;
                    grid-row: 4;
                }

                .input-wrapper--accident__ap_email {
                    grid-column: span 6;
                    grid-row: 4;
                }

                .input-wrapper--accident__ap_job_title {
                    grid-column: span 6;
                    grid-row: 5;
                }

                .input-wrapper--ap_dob {
                    grid-column: span 6;
                    grid-row: 7;
                }

                .input-wrapper--ap_gender {
                    grid-column: span 6;
                    grid-row: 7;
                }

                .input-wrapper--ap_work_status {
                    grid-column: span 12;
                    grid-row: 8;
                }

                .input-wrapper--injury_severity {
                    grid-column: span 12;
                    grid-row: 9;
                }

                .input-wrapper--accident__injury_body_parts {
                    grid-column: span 12;
                    grid-row: 10;
                }

                .input-wrapper--injury_details {
                    grid-column: span 12;
                    grid-row: 11;
                }

                .RenderAPTrainingCoursesList {
                    margin-top: 2rem;
                }

                .RenderAPQualificationsList {
                    margin-top: 2rem;
                }

                // "ap_name",
                // "ap_address",
                // "ap_contact_number",
                // "ap_email",
                // "ap_job_title",
                // "ap_gender",
                // "ap_dob",
                // "ap_work_status",
                // "injury_severity",
                // "injury_body_parts",
                // "injury_details",
            }

        }

        &.initialNearMissInfo {

            .employeeDetails {
                .input-wrapper--employee_name {
                    grid-column: span 6;
                    grid-row: 2;
                }

                .input-wrapper--employee_job_title {
                    grid-column: span 6;
                    grid-row: 2;
                }

                .input-wrapper--employee_email {
                    grid-column: span 6;
                    grid-row: 3;
                }
            }

            .recordOfIncident {

                .input-wrapper--occurred_at {
                    grid-column: span 6;
                    grid-row: 2;
                }

                .input-wrapper--location {
                    grid-column: span 6;
                    grid-row: 2;
                }

                .input-wrapper--incident_description {
                    grid-column: span 12;
                    grid-row: 3;
                }

                .input-wrapper--reported_to {
                    grid-column: span 6;
                    grid-row: 4;
                }

                .input-wrapper--reported_at {
                    grid-column: span 6;
                    grid-row: 4;
                }

                .input-wrapper--names_of_employees_involved {
                    grid-column: span 12;
                    grid-row: 5;
                }
            }

            .immediateActionsTaken {

                .input-wrapper--area_closed_off_or_restricted {
                    grid-column: span 4;
                    grid-row: 2;
                }

                .input-wrapper--work_prohibited {
                    grid-column: span 4;
                    grid-row: 2;
                }

                .input-wrapper--equipment_out_of_service_and_secured {
                    grid-column: span 4;
                    grid-row: 2;
                }

                .input-wrapper--interim_safety_procedures_implemented {
                    grid-column: span 4;
                    grid-row: 3;
                }
            }

            .HAndSUseOnly {
                .input-wrapper--h_and_s_department_comments {
                    grid-column: span 12;
                    grid-row: 2
                }

                .input-wrapper--confidential {
                    grid-column: span 4;
                    grid-row: 3;
                }
            }

        }

        &.nearMissFull {

            .employeeDetails {
                .input-wrapper--near_miss_report_number {
                    grid-column: span 4;
                    grid-row: 2;
                }

                .input-wrapper--employee_name {
                    grid-column: span 6;
                    grid-row: 3;
                }

                .input-wrapper--names_of_employees_involved {
                    grid-column: span 12;
                    grid-row: 4;
                }

                .input-wrapper--employee_job_title {
                    grid-column: span 6;
                    grid-row: 5;
                }

                .input-wrapper--employee_email {
                    grid-column: span 6;
                    grid-row: 5;
                }
            }

            .recordOfIncident {

                .input-wrapper--occurred_at {
                    grid-column: span 6;
                    grid-row: 2;
                }

                .input-wrapper--location {
                    grid-column: span 6;
                    grid-row: 2;
                }

                .input-wrapper--incident_description {
                    grid-column: span 12;
                    grid-row: 3;
                }

                .input-wrapper--cause {
                    grid-column: span 12;
                    grid-row: 4;
                }

                .input-wrapper--additional_comments {
                    grid-column: span 12;
                    grid-row: 5;
                }

                // .input-wrapper--reported_to {
                //     grid-column: span 6;
                //     grid-row: 6;
                // }

                // .input-wrapper--reported_at {
                //     grid-column: span 6;
                //     grid-row: 4;
                // }
            }

            .immediateActionsTaken {

                .input-wrapper--area_closed_off_or_restricted {
                    grid-column: span 4;
                    grid-row: 2;
                }

                .input-wrapper--work_prohibited {
                    grid-column: span 4;
                    grid-row: 2;
                }

                .input-wrapper--equipment_out_of_service_and_secured {
                    grid-column: span 4;
                    grid-row: 2;
                }

                .input-wrapper--interim_safety_procedures_implemented {
                    grid-column: span 4;
                    grid-row: 3;
                }
            }

            .HAndSUseOnly {
                .input-wrapper--h_and_s_department_comments {
                    grid-column: span 12;
                    grid-row: 2
                }

                .input-wrapper--confidential {
                    grid-column: span 4;
                    grid-row: 3;
                }
            }

        }

        &.signOff {
            .accidentDetails {

                .input-wrapper--ap_name {
                    grid-column: span 6;
                    grid-row: 2;
                }

                .input-wrapper--ap_job_title {
                    grid-column: span 6;
                    grid-row: 2;
                }

                .input-wrapper--ap_email {
                    grid-column: span 6;
                    grid-row: 3;
                }

                .input-wrapper--occurred_at {
                    grid-column: span 3;
                    grid-row: 4;
                }

                .input-wrapper--location {
                    grid-column: span 9;
                    grid-row: 4;
                }

                .input-wrapper--site_manager_at_time {
                    grid-column: span 3;
                    grid-row: 5;
                }

                .input-wrapper--work_process_involved {
                    grid-column: span 12;
                    grid-row: 6;
                }

                .input-wrapper--incident_description {
                    grid-column: span 12;
                    grid-row: 7;
                }
            }

            .injuryDetails {
                .input-wrapper--injury_severity {
                    grid-column: 1/ span 6;
                    grid-row: 2
                }

                .input-wrapper--injury_body_parts {
                    grid-column: span 6;
                    grid-row: 2
                }

                .input-wrapper--injury_details {
                    grid-column: span 6;
                    grid-row: 3
                }

                .input-wrapper--treatment {
                    grid-column: span 6;
                    grid-row: 3
                }

                .input-wrapper--fit_for_work {
                    grid-column: 1 /span 3;
                    grid-row: 4
                }

                .input-wrapper--time_off_required {
                    grid-column: 6 /span 2;
                    grid-row: 4
                }

                // .input-wrapper--riddor_required {
                //     grid-column: 11 /span 2;
                //     grid-row: 4
                // }
            }

            .HAndSUseOnly {
                .input-wrapper--h_and_s_department_comments {
                    grid-column: 1 /span 12;
                    grid-row: 2
                }

                .input-wrapper--signed_off_by {
                    grid-column: 1 /span 3;
                    grid-row: 3
                }

                // .input-wrapper--status { // currently managed in form settings panel
                //     grid-column: 9/ span 4;
                //     grid-row: 6;
                //     background-color: rgb(248, 242, 242);
                //     padding: 1rem;
                // }
            }
        }
    }
}

.fieldInfoBlock {
    height: 66vh;
    width: 400px;
    display: block;
    background-color: aliceblue;
    z-index: 10;
    grid-column: span 3;
    grid-row: 1;
    padding: 1.5rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: 1rem;
    margin-right: 2rem;
}

.response-history-container {
    position: relative;
    height: 300px;
    width: 100%;



    .response-history-wrapper {
        position: absolute;
        overflow: auto;
        height: 98%;
        width: 100%;
        background: white;
        padding: 0.5rem;
        border: 1px solid lightgrey;
        border-radius: 4px;

        .actionWrapper {
            //margin: 0.5rem;
            background: rgb(241, 247, 250);
            padding: 0.5rem;
            margin: 0.5rem;
            display: block;
            width: 100%;

            .actionLabel,
            .actionText {
                font-weight: 500;
            }
        }

        .commentWrapper {
            position: relative;
            width: 100%;
            padding: 0.5rem;
            display: block;
            text-align: right;

            .commentBubble {
                display: inline-block;
                border: 1px solid lightgrey;
                border-radius: 8px;
                background: aliceblue;
                width: 85%;
                padding: 0.5rem;

                &.initiallySelected {
                    border: 2px solid lightgrey;
                    box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2),
                        0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
                    padding: 0.75rem;
                }

                .submittedBy {
                    color: grey;
                    font-size: 0.8rem;
                }

                .submittedByNames {
                    color: grey;
                    font-size: 0.9rem;
                }

                .commentAt {
                    text-align: right;
                    font-size: 0.8rem;
                    font-weight: 400;
                    color: grey;
                }
            }

            .commentBubble.owner {
                background: $secondary;
            }
        }

        .commentWrapper.owner {
            text-align: left;
        }



    }

    // .response-history-item {
    //     overflow: hidden;
    // }
}

.CommentsInputWrapper {
    display: block;
    width: 100%;
    height: fit-content;

    .MuiFormControl-root {
        width: 100%;
        background: white;
        border-radius: 4px;
    }

}

.commentsSection {
    .sideHeader {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 1rem;
        padding-right: 0.5rem;

        .commentsSection-close-button {
            .MuiIconButton-root {
                background-color: $gray-lighter;
                padding: 6px;
            }
        }
    }

    .title {
        margin-bottom: 1.5rem;
        font-size: 1.2rem;
        max-width: 80%;
    }

    .generalActionFormWrapper {
        padding: 0;

        .generalActionFormGrid.fieldInfoControlPropsForm {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
        }

        .generalActionFormGrid.formSettingPropsForm {
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;

            .autoField-input-wrapper.input-wrapper--confidential {
                width: 300px;
            }

            &.near-miss {
                .autoField-input-wrapper.input-wrapper--confidential {
                    width: 190px;
                }
            }

        }

        // .input-wrapper--requires_resolution {
        //     grid-column: span 6;
        // }

        // .input-wrapper--field_redacted {
        //     grid-column: span 6;
        // }

        .MuiFormControlLabel-root {
            margin-left: 0;
            margin-top: 0.25rem;
        }
    }
}

.HASFormControlsWrapper {
    display: flex;
    flex-direction: column;
    background-color: $gray-lightest;
    padding: 0.5rem;
    margin-bottom: 1rem;
    //min-width: 400px;
    max-width: 400px;
    flex-wrap: wrap;
}

.autoField-input-wrapper {
    svg.flagged {
        color: red;
    }

    margin-bottom: 1rem;
}

.incidentDocumentsPanel h3 {
    font-size: 1.2rem;
}

.fullFormWrapper {
    position: absolute;
    width: 100%;
    top: 0;
    right: 0;
}

.fullScreenFormWrapper {
    &.fullScreen {
        background-color: white;

        @media print {

            .force-show-print,
            .no-print.force-show-print {
                display: initial !important;

                .MuiFormControlLabel-root,
                .MuiButtonBase-root {
                    display: inline-flex !important;
                }
            }


        }
    }

    .svg-inline--fa {
        color: #1e88e5;
    }

    .dx-editor-cell .dx-texteditor .dx-texteditor-input {
        line-height: 24px;
    }

    .MuiPaper-rounded {
        padding: 1.5rem;
        margin-top: 2rem;
        margin-bottom: 2rem;
        margin-left: 2rem;
        margin-right: 2rem;
    }

    &.fullScreen {
        position: absolute;
        background-color: white;

        // .dx-datagrid {
        //     height: 0;
        // }

        .dx-datagrid,
        .dx-scrollable-wrapper,
        .dx-scrollable-container,
        .dx-scrollable-content,
        .dx-datagrid-content {
            position: static !important;
        }

        .dx-datagrid-rowsview.dx-last-row-border.dx-scrollable.dx-visibility-change-handler.dx-scrollable-both.dx-scrollable-native.dx-scrollable-native-generic {
            position: static !important;
        }

        .dx-datagrid {
            position: static !important;
        }

        .dx-datagrid-rowsview.dx-last-row-border {
            position: static !important;
        }

        .dx-datagrid-rowsview {
            position: static !important;
        }

        visibility: hidden;

        .formWithComments {
            visibility: visible;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            overflow: visible;
            z-index: 1;
            height: fit-content;
            background-color: white;

            @media screen {

                // remember here we're in fullScreen mode
                &.commentsClosed {
                    .formSection {
                        .generalActionFormWrapper {
                            max-height: fit-content;
                            overflow-y: unset;
                            overflow-x: unset;
                        }
                    }
                }


            }

        }
    }
}

.dx-scrollable-wrapper,
.dx-scrollable-container,
.dx-datagrid-content {
    position: static;
}

.dx-datagrid-rowsview.dx-last-row-border.dx-scrollable.dx-visibility-change-handler.dx-scrollable-both.dx-scrollable-native.dx-scrollable-native-generic {
    position: static;
}

.dx-datagrid {
    position: static;
}

.formFieldFlagsWrapper {
    display: flex;
    flex-direction: column;

    svg {
        margin-top: 0.5rem;
    }
}


// print form specific rules
.fullScreenFormWrapper {
    .printable {

        table {
            width: 100%;
        }

        .incidentViewLogoWrapper {
            img {
                height: 20mm;
                width: auto;
            }
        }

        .viewSettings {
            border-bottom: none;
        }

        .RHSWrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin-right: 4%;
        }

        .formSection {

            width: 100%; //page-width

            .MuiPaper-elevation3,
            .MuiPaper-root,
            .MuiPaper-rounded {
                box-shadow: none !important;
                visibility: hidden !important;
                padding-left: 0 !important;
                padding-right: 0 !important;
                padding-bottom: 0 !important;
                padding-right: 0 !important;
                margin-left: 0 !important;
                margin-right: 0 !important;
                margin-bottom: 0 !important;
                margin-right: 0 !important;
                padding: 0 !important;
                margin: 0 !important;
            }

            background: transparent !important;
            text-shadow: none !important;
            box-shadow: none !important;

            .autoField-input-wrapper {
                margin-bottom: 0;
            }

            font-size: 16px;

            .generalActionFormGrid.HASIncidentDetailForm {

                .field-group-wrapper.HAndSUseOnly,
                .witnessStatementsWrapper.field-group-wrapper {
                    display: none !important;
                }

                // .field-group-wrapper {
                //     .input-wrapper--injury_diagram {
                //         display: none !important;
                //     }
                // }
            }

            .field-group-wrapper {
                visibility: visible;
                padding: 1rem;
                page-break-inside: avoid;
                border: solid $gray-lighter 2px;
                width: 96%;
                margin: 2%;

                .HAndSUseOnly {
                    display: none !important;
                }
            }

            &.column-flow {
                .field-group-wrapper {
                    display: flex;
                    flex-direction: column;
                }
            }
        }

        .incident-actions {
            visibility: visible;

            h1 {
                color: black !important;
            }
        }

        header.printableFormHeader,
        footer {
            width: 100%; //page-width
            height: 25mm;
            display: block !important;

        }

        header.printableFormHeader {
            width: 100%; //page-width
            position: absolute;
            top: 0;
        }

        @media print {

            header.printableFormHeader,
            footer {
                width: 100%; //page-width
                position: fixed;
            }

            footer {
                bottom: 0;
            }
        }

        @media print {

            table.paging thead td,
            table.paging tfoot td {
                height: 25mm;
            }
        }

        @page {
            width: 100%; //page-width
            margin: 25mm;
        }
    }
}

#incidentDetailModal {
    max-width: 98vw;

    .modal-body {
        min-height: 90vh; // stops it changing size so much (i.e. flickering) when changing tabs
    }

    .modal-title {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .modal-content {
        max-width: 98vw;
    }

    .title {
        display: flex;
        align-items: center;
    }

    .incidentHeaderDetail.closeButtonWrapper {
        .MuiButtonBase-root {
            color: white;
        }
    }
}

.extra-actions {
    display: flex;
    justify-content: flex-end;
}

.uploaderActionButtons {
    width: 100%;
    display: flex;
    justify-content: center;
}

.riddor-report-actions-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 1rem;
}

.riddorInfoListItem {
    margin-top: 1rem;
}


.accidentEventLogForm,
.nearMissEventLogForm {
    .autoField-input-wrapper.input-wrapper--details {
        grid-row: 2;
        grid-column: span 9;
    }

    .autoField-input-wrapper.input-wrapper--investigation_completed {
        grid-row: 2;
        grid-column-start: 10;
        grid-column-end: 13;
        justify-content: flex-end;
    }

    .autoField-input-wrapper.input-wrapper--resolution {
        grid-row: 3;
        grid-column: span 9;
    }

    .autoField-input-wrapper.input-wrapper--resolved_on {
        grid-row: 3;
        grid-column-start: 10;
        grid-column-end: 13;
        justify-content: flex-end;

    }

    .autoField-input-wrapper.input-wrapper--publish {
        grid-row: 4;
        grid-column-start: 12;
        grid-column-end: 13;
        justify-content: flex-end;
    }
}