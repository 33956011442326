// @import '../../../styles/app/common/variables.scss';

.MuiPickersDay-daySelected, .MuiPickersToolbar-toolbar {
  background-color: $blue-dark !important;
}

.MuiTypography-colorPrimary {
  color: $blue-dark !important;
}

.MuiFormControl-marginNormal {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  margin-right: .5rem !important;
  margin-left: .5rem !important;
}
