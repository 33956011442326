@import "../../../../../styles/app/common/variables.scss";

.reactiveByCategoryGridContainer {
  @media print {
    .dx-datagrid {
      max-height: 260px !important;
    }
  }

  .dx-texteditor-input {
    text-align: right;
  }

  .dx-data-row {
    height: 44px;
  }

  .buttons {
    text-align: right;

    border-bottom: none;
  }

  .dx-command-expand.dx-datagrid-group-space,
  .dx-command-edit.dx-command-edit-with-icons {
    background-color: #f2fafe;
    color: $dynamic-blue;
  }

  .dx-command-expand.dx-datagrid-group-space.dx-datagrid-expand.dx-selection-disabled {
    background-color: unset;
  }
}

.MuiFormControlLabel-root.show-archived-switch {
  margin-right: 1rem;
  margin-bottom: 0px;
  font-size: 0.9rem;
}

.toggleArchiveCategory button {
  padding: 0 !important;
}

tr.dx-row.dx-data-row.dx-row-lines.archived {
  td {
    color: $green;
    opacity: 0.75;

    .dx-icon-edit:before {
      color: $gray-med-dark
    }

    .dx-icon-edit:hover {
      cursor: default;
    }
  }

}