@import "../../../styles/app/common/variables.scss";

div.profile-modal {
  @media (min-width:1050px) {
    width: 800px;
  }
  @media (min-width:800px) and (max-width:1050px){
    width: 650px;
  }

  @media (max-width:800px){
    width: 500px;
  }

  .passwordRules{
    font-size: 1rem;
  }
  

  .modal-footer {
    background: $blue-dark;
  }
}
