@import '../../../../styles/app/common/variables.scss';

.AWDetailsWrapper {
    display: flex;
}

.AWDetails {
    width: 100%;
    padding-left: 1rem;
    padding-right: 1rem;

    .awDetailLabel {
        font-weight: 600;
    }

    .title {
        font-size: 1.2rem;
        font-weight: 400;
        color: rgba(50, 50, 50, 0.8);
    }

    .awDetailClientName {
        text-align: right;
    }

    .awDetailBlock.quoteFixedStatus {
        font-size: 1.5rem;
        text-transform: uppercase;
        color: rgba(50, 50, 50, 0.5);
        margin-top: 1rem;
        text-align: center;
        vertical-align: middle;
        //height: fit-content;
        min-height: 100px;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .detail {
            color: black;
            font-size: 14px;
            text-transform: none;
        }

        .MuiButtonBase-root {
            padding-top: 2px;

            .MuiButton-label {
                font-size: 0.8rem;
                text-transform: lowercase;
                margin-top: 0;
                text-decoration: underline;
            }

        }
    }
}

@media screen and (max-width: 1650px) {
    .awBlockContainer {
        .awDetailBlock.aw-description-block {
            grid-column: 1/13;
            grid-row: 1;
        }

        .awDetailBlock.aw-cost-breakdown-block {
            grid-column: 1/13;
            grid-row: 2;
        }

        .awDetailBlock.aw-purchase-order-block {
            grid-column: 1/ span 6;
            grid-row: 3;
            min-width: 300px;
        }

        .awDetailBlock.aw-invoice-block {
            grid-column: 1/ span 6;
            grid-row: 4;
        }

        .awDetailBlock.quoteFixedStatus {
            grid-column: 1/ span 6;
        }

        .awDetailBlock.aw-client-block {
            grid-column: 1/ span 6;
            grid-row: 5;
        }

        .awDetailBlock.aw-history-block {
            //grid-area: 1/9/5/13;
            grid-column: 7/ span 6;
            grid-row: 3/6;
            // grid-column: 5/7;
            // grid-row: 1/3;
        }


        .awDetailBlock.aw-documents-block {
            grid-column: 1/13;
            grid-row: 7;
        }

        .awDetailBlock.fp-notes-block {
            grid-column: 7/ span 6;
            grid-row: 7;
        }

        // .awDetailBlock.aw-comments-block {
        //     grid-column: 8/ span 5;
        // }
    }
}

@media screen and (min-width:1651px) {
    .awDetailBlock.aw-description-block {
        flex: 1 1 950px;
        grid-column: 1/9;
    }

    .awDetailBlock.aw-cost-breakdown-block {
        flex: 1 1 950px;
        grid-column: 1/9;

    }

    .awDetailBlock.aw-history-block {
        flex: 1 1 350px;
        grid-area: 1/9/6/13;
    }

    .awDetailBlock.aw-purchase-order-block {
        flex: 1 1 350px;
        grid-column: 1/ span 4;
        grid-row: 3/ span 2;
        min-width: 300px;
        max-width: 500px;
    }

    .awDetailBlock.quoteFixedStatus {
        grid-column: 1/ span 4;
    }

    .awDetailBlock.aw-client-block {
        //flex: 1 1 350px;
        grid-column: 5/ span 4;
        grid-row: 3;
    }

    .awDetailBlock.aw-invoice-block {
        flex: 1 1 350px;
        //grid-column: 9/ span 4;
        grid-column: 5/ span 4;
        grid-row: 4;
    }

    .awDetailBlock.aw-comments-block {
        flex: 1 1 350px;
        grid-column: 8/ span 5;
    }

    .awDetailBlock.aw-documents-block {
        flex: 1 1 950px;
        grid-column: 1/9;
        grid-row: 5;
    }

    .awDetailBlock.fp-notes-block {
        flex: 1 1 950px;
        grid-column: 9/ span 5;
        grid-row: 4/ span 2;
    }
}

.awBlockContainer {

    display: grid;
    //grid-template-columns: auto auto auto auto auto auto;
    grid-template-columns: repeat(12, minmax(0, 1fr)); //minmax(0, 1fr)
    min-height: 0;
    min-width: 0;
    //grid-template-rows: repeat(auto, 10);
    // flex-wrap:wrap;
    // display:flex;
    // justify-content: space-between;

    .awDetailBlock {
        min-width: 300px;
        //background:rgb(221,227,230);
        background: #eff3f5;
        //background: white;
        padding: 0.5rem;
        color: #1c3956;

        .filesUploadWrapper {
            padding: 1rem;
        }


    }

    .relegateAdditionalWorksButtonWrapper {
        width: 100%;
        text-align: right;

        .MuiButtonBase-root {
            padding-top: 2px;

            .MuiButton-label {
                font-size: 0.8rem;
                text-transform: lowercase;
                margin-top: 0;
                text-decoration: underline;
            }

        }
    }

    .awDetailBlock.aw-history-block {
        height: fit-content;

        button {
            float: right;
        }
    }

    .awDetailBlock.aw-purchase-order-block {

        .title {
            margin-bottom: 0;
        }

        .undoPOPendingWrapper {
            width: 100%;
            text-align: left;

            .MuiButtonBase-root {
                padding-top: 2px;

                .MuiButton-label {
                    font-size: 0.8rem;
                    text-transform: lowercase;
                    margin-top: 0;
                    text-decoration: underline;
                }

            }

        }

        .submitWithoutPODocButton {
            text-align: right;
            margin-top: 1rem;
        }

    }

    .awDetailBlock.aw-invoice-block {
        min-height: 100px;
    }

    .awDetailBlock.aw-documents-block {
        min-height: 100px;
        height: fit-content;
    }

    .awDetailBlock.aw-comments-block {
        min-height: 100px;
        height: fit-content;
    }

    .awReportsGridContainer {
        width: 100%;
    }

    td.currency,
    th.currency {
        text-align: right;
        padding-left: 1.5rem;
    }

    td.total {
        font-weight: 600;
        font-size: 0.9rem;
    }

    td,
    th {
        border-bottom: solid rgba(180, 180, 180, 0.8) 1px;
        padding: 0.25rem;
    }

    .POInputWrapper {
        display: block;
        width: 100%;
    }

    .CommentsInputWrapper {
        display: block;
        width: 100%;
        height: fit-content;

        .MuiFormControl-root {
            width: 100%;
            background: white;
            border-radius: 4px;
        }

        // .MuiInputBase-root {

        // }
        // fieldset {
        //     padding-bottom: unset;
        //     //border-bottom: unset;
        //     margin-bottom: unset;
        //     &.last-child,
        //     &:last-child {
        //       border-bottom: 1px solid rgba(0,0,0,0.23);
        //     }
        //     .form-group {
        //       margin-bottom: unset;
        //     }
        //   }

    }

    .POActionsWrapper {
        padding-top: 1rem;
        width: 100%;
        display: flex;
        justify-content: flex-end;

        .declineQuoteButton {
            color: #c10303
        }
    }

    .subtitle {
        font-size: 1rem;
        font-weight: 400;
        padding-bottom: 7px;
        display: inline-block;
    }

    .response-history-container {
        position: relative;
        height: 300px;
        width: 100%;

        .response-history-wrapper {
            position: absolute;
            overflow: auto;
            height: 98%;
            width: 100%;
            background: white;
            padding: 0.5rem;
            border: 1px solid lightgrey;
            border-radius: 4px;

            .actionWrapper {
                //margin: 0.5rem;
                background: rgb(241, 247, 250);
                padding: 0.5rem;
                margin: 0.5rem;
                display: block;
                width: 100%;

                .actionLabel,
                .actionText {
                    font-weight: 500;
                }
            }

            .commentWrapper {
                position: relative;
                width: 100%;
                padding: 0.5rem;
                display: block;
                text-align: right;

                .commentBubble {
                    display: inline-block;
                    border: 1px solid lightgrey;
                    border-radius: 8px;
                    background: aliceblue;
                    width: 85%;
                    padding: 0.5rem;

                    .submittedBy {
                        color: grey;
                        font-size: 0.8rem;
                    }

                    .submittedByNames {
                        color: grey;
                        font-size: 0.9rem;
                    }

                    .commentAt {
                        text-align: right;
                        font-size: 0.8rem;
                        font-weight: 400;
                        color: grey;
                    }
                }

                .commentBubble.owner {
                    background: $secondary;
                }
            }

            .commentWrapper.owner {
                text-align: left;
            }



        }

        // .response-history-item {
        //     overflow: hidden;
        // }
    }

    .key-dates-container {
        position: relative;
        overflow: auto;
        height: 50%;
        width: 100%;
    }


}

.awDetailBlock {
    margin: 1rem;
}