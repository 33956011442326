@import "src/styles/app/common/variables.scss";

.custom-paginator-wrapper {
    display: flex;
    gap: 20px;
    min-width: 300px;
    justify-content: flex-end;
    padding: 0.5rem 0;

    @media print {
        display: none !important;
    }

    .page-number {
        height: 24px;
        min-width: 24px;
        cursor: pointer;
        padding: 0 8px;
        color: $gray-dark;
        font-size: 0.9rem;
    }

    .page-number.selected {
        background-color: $dynamic-blue;
        color: white;
        border-radius: 12px;
        text-align: center;
        cursor: unset;
    }
}

#scheduleGridContainerMaintenanceVisits,
#scheduleGridContainerHASChecks {
    .dx-datagrid-pager {
        display: none;
    }
}