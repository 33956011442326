@import '../../styles/app/common/variables.scss';

.scheduleListStatsWrapper {
    display: flex;
    font-size: 0.9rem;
    color: $gray-med-dark;
    width: 100%;

    .scheduleListStatsGroup {
        margin-right: 2rem;

        .scheduleListStatLabel {
            font-weight: 600;
            margin-right: 0.5rem;
        }
    }

    justify-content: space-between;
    align-items: center;
}

.supplierVisitListWrapper {
    padding: 1rem;
    margin-bottom: 2rem;
}

.visitStatWarningTooFew {
    color: red;
}

.visitStatInfoExcess {
    color: $dynamic-blue;
}

#scheduleListGridContainer {

    td span.warn,
    td span.info {
        position: relative;
    }

    .warn:after {
        content: "";
        position: relative;
        width: 15px;
        left: 0;
        border-bottom: 2px solid red;
        display: block;
    }

    .info:after {
        content: "";
        position: relative;
        width: 15px;
        left: 0;
        border-bottom: 3px solid green;
        display: block;
    }

    .info {
        color: green;
    }

    .warn {
        color: red;
    }

    // .warn:hover:after {
    //     content: "No service report!";
    //     color: grey;
    //     font-size:0.9em;
    //     padding-left:10px;
    // }
}

tr.dx-row.dx-data-row.dx-row-lines.preview {
    td {
        color: $green;

        .dx-icon-edit:before {
            color: $gray-med-dark
        }

        .dx-icon-edit:hover {
            cursor: default;
        }
    }

}

#scheduleListActionButtonsWrapper {
    display: flex;
    width: 650px;
    justify-content: flex-end;
    padding-bottom: 0.8rem;

    .buttonWrapper {
        padding-left: 1rem;
    }

    .MuiButtonBase-root {
        background-color: $blue-dark;
    }
}

.cell-content-link {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    flex-direction: column;

    &:hover {
        font-weight: bold;
    }

    &.warn {
        color: red;
    }

    &.info {
        color: green;
    }
}

.scheduleNotesIcon {
    color: $dynamic-blue;
}

#scheduleGridContainerMaintenanceVisits .withNotes .scheduleNotesIcon,
#scheduleGridContainerHASChecks .withNotes .scheduleNotesIcon {
    color: $blue-dark;
}

.withNotes .scheduleNotesIcon {
    color: $success;
}

.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row>td.customActionsColumn {
    padding-left: 0;
}