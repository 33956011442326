@import '../common/variables.scss';

/* ========================================================================
     Component: print
 ========================================================================== */

// Put here all rules required to style the print view

@media print {

  .sidebar,
  .topnavbar,
  .offsidebar,
  .btn {
    display: none !important;
    width: 0 !important;
    height: 0 !important;
  }

  .wrapper,
  .wrapper .section-container,
  .content-wrapper {
    margin: 0 !important;
    /* remove margin used for sidebar and expand the content */
    padding: 0 !important;
    width: 100% !important;
    border: 0;
    /* optional: remove top border used to separate the page heading */
  }

  .content-wrapper {
    overflow: hidden !important;
  }

  /* hide sidebar container */
  .aside-container {
    display: none !important;
  }

  /* remove space used for the sidebar */
  .footer-container {
    margin: 0 !important;
  }

}

@media screen {
  .no-screen {
    display: none;
  }
}

@media print {

  .no-print,
  .no-print * {
    display: none !important;
  }

  // .no-screen {
  //   display: block;
  // }

  * {
    background: transparent !important;
    text-shadow: none !important;
    box-shadow: none !important;
  }

  .MuiPaper-root {
    padding: 0px !important;
    margin: 0px !important;
  }

  /* Grid related styling */
  #data-chart {
    padding: 0px !important;
    margin: 0px !important;
  }

  .dx-datagrid-text-content {
    margin-right: -20px;
    white-space: normal !important;
  }

  /* Font */
  .dx-row td,
  .dx-datagrid-text-content {
    font-size: 12px;
  }

  /* Removal of leading controls*/
  .dx-datagrid-header-panel {
    display: none;
  }

  /* Max grid height to 1 page and keep on a single page*/
  .dx-datagrid {
    page-break-inside: avoid;
  }

  /* fixed grid sizes */
  #maintenance-grid .dx-datagrid {
    max-height: 230px !important;
  }

  td.dx-command-edit a {
    display: none !important;
  }

  .dx-datagrid-filter-row {
    display: none !important;
  }

  .dx-datagrid-rowsview {
    overflow: unset !important;
  }

  .dx-scrollable-container {
    overflow: unset !important;
  }

  .contract-report-block {
    page-break-after: always;
    height: fit-content;
  }

  // Print Grid
  thead {
    display: table-header-group;
  }

  tfoot {
    display: table-header-group;
  }

  #chart>svg {
    -webkit-tap-highlight-color: unset;

    &>rect {
      visibility: hidden;
      opacity: 1 !important;
      width: 0 !important;
      height: 0 !important;
    }
  }

  // Material UI
  .MuiButtonBase-root,
  .MuiIconButton-root {
    display: none !important;
  }

  // Specialist ==
  #specialistLegend {
    width: 96% !important;
    margin-left: 2%;
    border: none !important;
    padding-bottom: 1.5rem;

    &>div {
      width: 14% !important;

      display: inline-block !important;
    }

    .specialist-flag {
      display: inline-block !important;
    }
  }

  #scheduleGridContainerMaintenanceVisits,
  #scheduleGridContainerHASChecks {

    // Data rows
    .dx-row.dx-data-row.dx-row-lines td {
      height: 8px !important;

      .specialist-flag {
        display: block;
      }

      // Contractor / Equipment fields
      span.fixed-col-value {
        padding-top: 2px !important;
        display: inline-block;
      }
    }

    // Delete and Restore icons
    .fa-trash,
    .fa-redo {
      display: none !important;
    }

    // Group headers
    tr.dx-row.dx-group-row {
      td {
        height: 25px !important;
        padding: 5px 0 0 5px !important;
      }

      // dropdown
      .dx-datagrid-group-opened {
        display: none;
      }
    }

    // Column headers
    tr.dx-row.dx-header-row td {
      height: 25px !important;
      padding-top: 4px !important;
    }
  }

  .dx-datagrid-headers .dx-datagrid-table .dx-row>td {
    background: $brandGoldDarker !important;
  }

  .dx-datagrid-headers .dx-datagrid-table .dx-row>td {
    background: $brandGold !important; //#fcc832 !important
  }
}