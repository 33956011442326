@import '../../../../styles/app/common/variables.scss';

#documentSelectorModal {

  width: 100%;
  margin-top: 3vh;
  height: 80vh;
  padding: 0;

  .modal-content {
    height: 100%;
    min-height: 100%;
    border-radius: 0;
  }

  .dx-texteditor.dx-editor-underlined:after {
    border-bottom: none;
  }

  .modal-footer {
    background-color: $blue-dark;
    color: $white;
    padding: 0;
    height: 10px;
  }

  .modal-header {
    cursor: default;

    .MuiIconButton-root {
      color: $white;
      padding: 0;
    }

    .modal-title {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    padding-top: 8px;
    padding-bottom: 8px;
  }

  .modal-body {
    padding-top: 0;
  }

  .document-selector {
    &--close-button {
      justify-self: flex-end;
    }
  }

  @media screen and (min-width: 1240px) {
    &.modal-xl {
      max-width: 1200px;
    }
  }

  @media screen and (min-width: 1440px) {
    &.modal-xl {
      max-width: 1320px;
    }
  }

  @media screen and (min-width: 1600px) {
    &.modal-xl {
      max-width: 1480px;
    }
  }


}