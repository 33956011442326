@import '../../../../styles//app/common/variables.scss';

.apprise-form-group-item.coloured {
  background-color: $blue-dark;
  margin-top: 3rem;
  color: white;

  .dx-checkbox-icon {
    border: 2px solid white;
  }
}

.apprise-form-group-item.coloured .dx-field-item-label-text {
  color: white;
}

.apprise-form-group-item.apprise-edit-form {
  padding-left: 2rem;
}

.dx-datagrid-rowsview .dx-row>.dx-master-detail-cell {
  padding: 0;
  padding-bottom: 1.25rem;
}

.dx-cell-focus-disabled.dx-master-detail-cell {
  text-align: left;
  background-color: #56b0ff0f;
  border-bottom: 2px solid #49617842;
}

.dx-tabs-wrapper,
.dx-item.dx-tab.dx-tab-selected,
.dx-item.dx-tab {
  background-color: $blue-dark;
  color: white;
}

.dx-item.dx-tab:hover {
  background-color: #507ba7;
}

.dx-list-item {
  text-overflow: unset;
  overflow-x: visible;
}

.admin-form {
  display: flex;
  flex-wrap: wrap;

  .dx-box-flex .dx-box-item-content {
    min-width: 250px;
  }

  .dx-field-item-label-location-top {
    padding: 6px 0 1px;
  }

  // MuiButton-label
  .button-reset {
    color: $gray-dark;
  }

  .button-save {
    color: $dynamic-blue;
  }

  .widget-container {
    flex-grow: 1;
    padding: 20px 20px 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .options {
    flex-grow: 0;
    width: 350px;
    padding: 20px;
    background-color: rgba(191, 191, 191, 0.15);
    min-height: 315px;
  }


  .caption {
    font-size: 18px;
    font-weight: 500;
  }

}