@import './variables.scss';

.material-dialog {
  min-width: 1000px;

  .header-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .MuiDialogContent-root {
    
    .MuiDialogActions-root {
      padding: 8px 0;
      margin-right: -10px;
    }
  }

  .MuiDialogTitle-root {
    background-color: $blue-dark;
    color: $white;

    .visit-modal {
      &--title {
        flex-grow: 1;
        text-align: center;
      }

      &--dim {
        color: $gray-med-dark;
      }
    }

    .MuiSvgIcon-root {
      color: $white;
      padding: 0;
    }

    .MuiIconButton-root {
      padding: 0;
    }

    .MuiSvgIcon-root {
      font-size: 1.75rem;
    }
  }
}

.MuiButton {
  &__primary {
    background: $brandGold !important;
    color: $white !important;

    transition: background-color 0.25s ease;

    &:hover {
      background: $brandGoldDarker !important;
    }
  }

  &__secondary {
    border: 1px solid $gray !important;
    color: $dynamic-blue !important;
  }
}

.MuiDialogTitle-root {
  background-color: $blue-dark;
  color: $white;
  text-align: center;
}
