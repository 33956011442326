@import "../../../styles/app/common/variables.scss";

#scheduleGridContainerMaintenanceVisits,
#scheduleGridContainerHASChecks {
  .buttons {
    text-align: right;
    border: 1px solid $table-border-highlight;
    border-bottom: none;
  }

  .dx-datagrid .dx-row>td {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .dx-row td {
    height: 71px;
  }

  .dx-row.dx-group-row td,
  .dx-row.dx-header-row td {
    height: 48px !important;
  }

  .fas {
    cursor: pointer;
    color: $dynamic-blue;
  }

  .MuiIconButton-root {
    padding: 0;
    color: $dynamic-blue;
  }

  .dx-datagrid-content .item-clickable {
    span.fixed-col-value.OutOfContract {
      color: red !important;
    }
  }

  .dx-datagrid-content {
    span.fixed-col-value.OutOfContract {
      color: red !important;
    }
  }

  .visitsCountVarianceWrapper {
    height: 0;
    position: relative;

    .visitsCountVariance {
      position: relative;
      //left: -1.75rem;
      top: 2px;

      .inner {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        /* line-height: 100%; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        /* padding: 3px; */
        background: red;
        color: white;
      }
    }

    &.excess {
      top: -30px;

      .inner {
        background: $dynamic-blue;
      }
    }
  }

  td.dx-command-edit.dx-command-edit-with-icons {
    overflow: visible;
  }

}