// Cutoms Gray colors for theme

$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;

$gray-darker: #232735;
$gray-dark: #3a3f51;
$gray-darker-med: #5f5f5f;
$gray-dark-med: #989898;
$gray-med-dark: #b1b1b1;

$gray: #d6d6d8; //8B8A8C
$gray-light: #e4eaec;
$gray-lighter: #edf1f2;
$gray-lightest: #f4f8f9;

$table-background-highlight: #cae9fb3d;
$table-border-highlight: #cae9fb;

// Custom brand colors for theme
$dynamic-blue: #1e88e5;

$inverse: #131e26;
$purple: #7266ba;
$pink: #f532e5;
$yellow: #ffcb05; //#ffcc00
$red: rgb(199, 30, 30);

$green: #469f67; //#37bc9b;

$blue-dark: #08487C; //1c3956;

$primary: $dynamic-blue;
$secondary: #fff;
$success: #27c24c;
$info: #46709a;
$warning: #ff902b;
$danger: #f05050;
$dark: #3a3f51;
$black: #000000;
$reactiveColor: #FA991C;
$scheduledColor: #1C768F;

// Custom color variants
$amountOfLight: 10%;
$amountOfLightBoost: 15%;

$blue-dark-light: lighten($blue-dark, $amountOfLight);

$black-light: lighten($black, $amountOfLight);
$primary-light: lighten($primary, $amountOfLight);
$success-light: lighten($success, $amountOfLight);
$info-light: lighten($info, $amountOfLight);
$warning-light: lighten($warning, $amountOfLight);
$danger-light: lighten($danger, $amountOfLight);
$inverse-light: lighten($inverse, $amountOfLight);
$green-light: lighten($green, $amountOfLight);
$pink-light: lighten($pink, $amountOfLight);
$purple-light: lighten($purple, $amountOfLight);
$yellow-light: lighten($yellow, $amountOfLight);
$primary-dark: darken($primary, $amountOfLight);
$success-dark: darken($success, $amountOfLight);
$info-dark: darken($info, $amountOfLight);
$warning-dark: darken($warning, $amountOfLight);
$danger-dark: darken($danger, $amountOfLight);
$inverse-dark: darken($inverse, $amountOfLight);
$green-dark: darken($green, $amountOfLight);
$pink-dark: darken($pink, $amountOfLight);
$purple-dark: darken($purple, $amountOfLight);
$yellow-dark: darken($yellow, $amountOfLight);
$red-dark: darken($red, $amountOfLight);

// Theme variables

// used for sidebars and content spacing
$navbar-height: 64px;

// Button secondary redefinition style for theme
$btn-secondary-color: #333;
$btn-secondary-bg: #fff;
$btn-secondary-border: #eaeaea;

// Top Border color for custom default cards (.card-default)
$card-default-border-color: #cfdbe2;

// ---------------------
// Utilities variables
// ---------------------

$text-alpha: rgba(255, 255, 255, 0.5);
$text-alpha-inverse: rgba(0, 0, 0, 0.5);

$font-size-base: 1rem;
$text-sm: $font-size-base * 0.74375;
$text-md: $font-size-base * 1.6625;
$text-lg: $font-size-base * 2.625;

// Font Family
$fontFamiy: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
  "Helvetica Neue", Arial;

$border-color: rgba(0, 0, 0, 0.12);

$wd-xxs: 60px;
$wd-xs: 90px;
$wd-sm: 150px;
$wd-sd: 200px; // sd == standard
$wd-md: 240px;
$wd-lg: 280px;
$wd-xl: 320px;
$wd-xxl: 360px;
$wd-wide: 100%;
$wd-auto: auto;
$wd-zero: 0;

// ---------------------
// Bootstrap 4 Variables Override
// ---------------------

// Color system

$colors: (
  "purple": $purple,
  "pink": $pink,
  "yellow": $yellow,
  "red": $red,
  "green": $green,
  "primary": $primary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger,
  "inverse": $inverse,
  "primary-light": $primary-light,
  "primary-dark": $primary-dark,
  "success-light": $success-light,
  "success-dark": $success-dark,
  "info-light": $info-light,
  "info-dark": $info-dark,
  "warning-light": $warning-light,
  "warning-dark": $warning-dark,
  "danger-light": $danger-light,
  "danger-dark": $danger-dark,
  "green-light": $green-light,
  "green-dark": $green-dark,
  "pink-light": $pink-light,
  "pink-dark": $pink-dark,
  "purple-light": $purple-light,
  "purple-dark": $purple-dark,
  "inverse-light": $inverse-light,
  "inverse-dark": $inverse-dark,
  "yellow-light": $yellow-light,
  "yellow-dark": $yellow-dark,
  "dynamic-blue": $dynamic-blue,
  "reactiveColor": $reactiveColor,
  "scheduledColor": $scheduledColor,
);

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $success,
  "info": $info,
  "warning": $warning,
  "danger": $danger
);

// Body

$body-bg: #f5f7fa;
$body-color: #656565;

$border-radius: 0.25rem;
$border-radius-lg: 0.375rem;

$line-height-base: 1.528571429;

$headings-line-height: 1.1;

$text-muted: #909fa7;

// Tables

$table-accent-bg: #fafbfc;
$table-hover-bg: #fafbfc;

$table-border-color: #eee;

// Buttons + Forms

$input-btn-padding-y: 0.375rem;
$input-btn-padding-x: 1rem;

$input-btn-padding-y-sm: 0.3125rem;
$input-btn-padding-x-sm: 0.625rem;

$input-btn-padding-y-lg: 0.625rem;
$input-btn-padding-x-lg: 1.25rem;

$input-height: 2.1875rem; // 35px

$right-visibility-menu-padding: 0.75rem;

// Forms

$input-disabled-bg: $gray-lighter;
$input-border-color: $gray;
$input-focus-border-color: #66afe9;
$input-placeholder-color: #b7bac9;

$form-feedback-icon-valid: none;
$form-feedback-icon-invalid: none;

// Dropdowns

$dropdown-border-color: #e1e1e1;

// Navs

$nav-tabs-link-active-bg: #fff;

// Jumbotron

$jumbotron-bg: #fcfcfc;

// List group

$list-group-border-color: #e4eaec;

// Image thumbnails

$thumbnail-padding: 0.125rem;

// Breadcrumbs

$breadcrumb-bg: #fafafa;
$breadcrumb-divider-color: #c1c2c3;
$breadcrumb-active-color: $body-color;

$md-padding: 0.625rem;

$azure: #5893ce;
$white: #ffffff;
$whiteTransparent: rgba(255, 255, 255, 0.5);

$padding-header: 3px;
$padding-header-3x: 9px;
$margin-left-for-right-bar: 60px;

$blue: #1b3955;
$text-main: #fff;

$active-opacity: 0.8;
$disabled-opacity: 0.4;
$gold: #ffd700;
$brandGold: #FFCB05; //#fcc832;
$lemonChiffon	: #fffacd;

$brandGoldDarker: darken($brandGold, $amountOfLightBoost);

$blue-light: #eef5f9;
$red-light: #ffe9e9;
//$red-light: #af02026b;

:export {
  textMain: $text-main;
  blue: $blue;
  blueDark: $blue-dark;
  red: $red;
  blueLight: $blue-light;
  redLight: $red-light;
  lemonChiffron: $lemonChiffon;
  blueDarkLight: $blue-dark-light;
  azure: $azure;
  yellow: $yellow;
  green: $green;
  white: $white;
  whiteTransparent: $whiteTransparent;
  black: $black;
  reactiveColor: $reactiveColor;
  scheduledColor: $scheduledColor;
  blackLight: $black-light;
  danger: $danger;
  dangerLight: $danger-light;
  dangerDark: $danger-dark;
  paddingHeader: $padding-header;
  paddingHeader3x: $padding-header-3x;
  marginLeftForRightBar: $margin-left-for-right-bar;
  activeOpacity: $active-opacity;
  disabledOpacity: $disabled-opacity;
  success: $success;
  successLight: $success-light;
  gray: $gray;
  grayDark: $gray-dark;
  grayDarker: $gray-darker;
  grayMedDark: $gray-med-dark;
  grayMedDarker: $gray-dark-med;
  grayLight: $gray-light;
  grayLighter: $gray-lighter;
  primaryDark: $primary-dark;
  primaryDark: $primary-dark;
  gold: $gold;
  brandGold: $brandGold;
  brandGoldDarker: $brandGoldDarker;
  fontFamily: $fontFamiy;
  dynamicBlue: $dynamic-blue;
  purple: $purple;
  navbarHeight: $navbar-height;
  rightVisibilityMenuPadding: $right-visibility-menu-padding;
  tableBorderHighlight: $table-border-highlight;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(-359deg);
  }
}

.white {
  color: white !important;
}