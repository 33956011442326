@import '../../styles/app/common/variables.scss';

.scheduleLegend {
  font-weight: 500;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 10px;
  border: 1px solid $table-border-highlight;
  margin-right: 10px;
  padding: 1rem;
  background-color: $white;

  .specialist-flag {
    display: inline-block;
  }
}

#HASTaskLegend {
  padding-left: 3rem;
  padding-right: 3rem;
}