@import "../../../../styles/app/common/variables";

.dx-datagrid .selected div[role=button] {
  background-color: $blue-dark;
  color:white;
}

#linkSubmissionsButton.submission-ready[role=button] {
  background-color: $brandGold;
  opacity: 0.75;
  &:hover {
    opacity:1;
  }
}