@import '../../../../styles/app/common/variables.scss';

#adminPanelHeader {
  .modal-title {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .admin-panel-tabs {
      //width: 100%;
      display: flex;
      flex-direction: row;
      width:20%; //specified just ensure the same as .admin-panel-controls so flex centers title

      // em {
      //   color: $blue-dark;
      //   margin-right: 24px;
      // }
    }

    .admin-panel-title {
      text-align: center;
      width: 60%;//specified to ensure gap between title and controls on either side
      h2 {
        font-size:1.25rem;
        margin:0;
      }
    }

    .admin-panel-controls {
      flex-direction: row;
      display: flex;
      width:20%; //specified to ensure the same as .admin-panel-tabs so flex centers title
      justify-content: flex-end;
      //margin-right: 0px;
      & div em {
        margin-right: 4px;
        margin-left: 0.75rem;
      }
    }    
  }
}

#adminPanelHeader.task-mode {
  background-color: $blue-dark;
  .admin-panel-title{
    color:white;
  }

}
