@media print {
  .print-section-indicator {
    width: 1px !important;
    height: 1px !important;
    padding: 0 !important;
    overflow: hidden !important;
    opacity: 0 !important;
  }
}

@media screen {
  .print-section-indicator {
    display: none;
  }
}
