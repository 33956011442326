.incidentImagesWrapper {
    display: flex;

    .imagesGridImageWrapper {
        margin-bottom: 1rem;
    }
}

.loadingControl {
    &.loading {
        opacity: 0;
    }

    transition: opacity 0.5s;
}