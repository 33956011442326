.support-link {
    svg {
        font-size: 1.8rem;
        fill: none;
        stroke: white;
        outline: none;
        margin-right: 1rem;
    }
}

.top-bar-items-wrapper {
    display: flex;
    min-height: 64px;
    align-items: center;
    padding: 0 1.5rem;
    justify-content: space-between;
}

.top-bar-items-right-group {
    display: flex;
    height: 100%;
    align-items: center;
}

#BaseHeaderHorizontal {
    @media print {
        display: none !important;
    }
}