/*!
 *
 * Jaguar Apprise App Styles 
 *
 *
 */
// Bootstrap
@import "bootstrap/functions";
@import "bootstrap/variables";
@import "bootstrap/mixins";


// Variables
@import "app/common/variables";
// Layout
@import "app/layout/layout";
@import "app/layout/layout-extra";
@import "app/layout/layout-animation";
@import "app/layout/top-navbar";
@import "app/layout/sidebar";
@import "app/layout/offsidebar";
@import "app/layout/user-block";
@import "app/layout/settings";
// Common
@import "app/common/page-loader";
@import "app/common/typo";
@import "app/common/bootstrap-reset";
@import "app/common/bootstrap-custom";
@import "app/common/button-extra";
@import "app/common/placeholder";
@import "app/common/cards";
@import "app/common/circles";
@import "app/common/dropdown-extra";
@import "app/common/half-float";
@import "app/common/animate";
@import "app/common/slim-scroll";
@import "app/common/inputs";
@import "app/common/utils";

// Material
@import "app/material/dialog";
@import "app/common/material-ui";

// Images
@import "app/media/file-types";

// Overrides
@import "app/common/dev-extreme-overrides";
@import "app/material/date-picker";

// Print
// @import "app-print";


@media screen {
  .integrityError {
    background-color: yellow !important;
  }
}

.capitalize {
  text-transform: capitalize;
}