@import './variables.scss';

.dx-link.dx-icon.fas {
  width: 14px !important;
  height: 14px !important;
  font-size: 14px !important;
}

.compact-grid {
  .dx-datagrid-headers .dx-datagrid-table .dx-row>td {
    padding-top: 10px;
    padding-bottom: 10px;
    //font-size: 14px;
  }

  .dx-row.dx-datagrid-filter-row {
    .dx-editor-cell {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}

.dx-overlay-wrapper.dx-datagrid-edit-popup.dx-popup-wrapper.dx-overlay-modal {
  z-index: 3500 !important;
}

// Dropdown datagrid over admin panel
.dx-overlay-wrapper.dx-dropdowneditor-overlay.dx-popup-wrapper.dx-dropdownlist-popup-wrapper.dx-selectbox-popup-wrapper {
  z-index: 3500 !important;
  //width:auto; // HT Note - this has no effect - seems to be set in JS by DX

  .dx-list-item {
    display: flex; // HT Note - this is here so that when the pop up wrapper is set to width auto, it will shrink to the widest text item
  }

}

.dx-overlay-wrapper.dx-dropdowneditor-overlay.dx-popup-wrapper.dx-datebox-wrapper.dx-datebox-wrapper-date.dx-datebox-wrapper-calendar {
  z-index: 3500 !important;
}

.dx-overlay-wrapper.dx-dialog.dx-popup-wrapper.dx-dialog-wrapper.dx-dialog-root.dx-overlay-modal.dx-overlay-shader {
  z-index: 3505 !important;
}

// Set material border colours and alternate row colours
.dx-datagrid .dx-row-alt>td {
  background-color: #EEF5F9;
  opacity: .8;
}

.dx-datagrid .dx-row-lines>td {
  border-bottom: 1px solid $table-border-highlight;
}

.dx-datagrid-borders>.dx-datagrid-headers,
.dx-datagrid-headers .dx-datagrid-table .dx-row>td,
.dx-row>td,
.dx-row>tr>td {
  border-color: $table-border-highlight;
  border-right: 0;
  border-left: 0;
}

// .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
//   font-size: 1rem;
// }

.dx-datagrid.dx-gridbase-container.dx-datagrid-borders {
  border: 1px solid $table-border-highlight;
}

.dx-toolbar .dx-toolbar-items-container {
  height: 46px;
}

.dx-datagrid-header-panel {
  padding-right: .25rem;
}

@media print {
  .dx-datagrid-text-content {
    white-space: normal !important;
  }
}

.dx-datagrid-content .dx-data-row td {
  border-bottom: 1px solid #caeaff !important;
}

.dx-datagrid .dx-link {
  color: $dynamic-blue !important;
}

.dx-jaOverride,
.dx-save,
.dx-submit,
.dx-cancel,
.dx-delete {
  background-color: $white;
  border: unset;
  color: $dynamic-blue;
  text-transform: uppercase;
  font-weight: 600;
  font-size: .95em;

  &.Mui-disabled {
    padding-top: 3px; // quick fix to bring ToolttipWithButton into line with other non MUI buttons next to it
    font-size: .95em;
  }
}

.dx-datagrid-borders>.dx-datagrid-rowsview {
  border-left: 0px !important;
  border-right: 0px !important;
}

.dx-datagrid-content {
  .svg-inline--fa {
    color: $dynamic-blue;
  }
}

.dx-datagrid-jt .dx-datagrid {

  // border: 0px !important;
  .dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row)>td:not(.dx-focused) {
    background-color: #0082ff2e !important;
    color: black !important;
  }

  .dx-datagrid-headers {
    border-top: 0px !important;
    ;
  }

  // ==== Column header ====
  &.dx-datagrid-no-borders {
    border: 0px !important;

    .dx-datagrid-headers {
      border-top: 0px !important;
      ;
      border-bottom: 0px !important;
      ;
    }

    .dx-datagrid-rowsview {
      border: 0px !important;
    }

    &.dx-gridbase-container.dx-datagrid-borders {
      border: 0px !important;
    }
  }

  .dx-header-row td {
    font-size: .9rem !important;
  }

  // ==== Data Row ====
  .dx-data-row td {
    font-size: .8rem !important;
    height: 38px !important;
  }

  .dx-row>td {
    padding-top: 7px !important;
    padding-bottom: 7px !important;
    // border-bottom: 0px;
  }


  .dx-datagrid-search-text {
    color: $brandGoldDarker !important;
    background-color: unset !important;
  }

  // ==== Search Panel ====
  .dx-texteditor {
    width: 100% !important;
  }

  .dx-toolbar .dx-toolbar-after {
    padding-left: 0px;
    width: 100% !important;
  }

  .dx-datagrid-search-panel {
    margin-left: 0px;
  }

  .dx-datagrid-header-panel {
    padding-right: 0;
    padding-left: 0;
  }
}

.dx-datagrid .dx-datagrid-content .dx-datagrid-table .dx-row>td.dx-command-edit.dx-command-edit-with-icons .dx-link {
  margin: 0 4px;
}