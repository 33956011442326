.month-year-selector {
  display: flex;

  > * {
    margin-right: .5rem;
  }

  &__month-options {
    padding-right: .25rem;
  }
}