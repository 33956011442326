/*!
 *
 * Jaguar Apprise Print Styles 
 *
 *
 */

@import "app/print/print";
@import "app/print/data-grid";
@import "app/print/header";
