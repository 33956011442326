@import "../../../styles/app/common/variables";

@media print {
  #gridHeader {

    h1 {
      font-size: 1.5rem;
      display: inline-block;
      padding-bottom: 5px;
      position: relative;
      &:before {
        content: "";
        position: absolute;
        width: 100px;
        height: 1px;
        bottom: 0;
        left: 0;
        border-bottom: 5px solid $brandGold;
        display: block;
      }
    }

    h2 {
        font-size: 1.15rem;
    }

    h3 {
      font-size: 0.95rem;
    }

    

    
  }

  

  #tableChart {
    margin: 0;
    page-break-after: always;

  .MuiPaper-rounded {
      min-height: 300px;
      display: block;
    }
  }
}
