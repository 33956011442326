@import './src/styles/app/common/variables.scss';

.imagesGrid {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr)); //minmax(0, 1fr)
    min-height: 50vh;

    &.loading {
        opacity: 0;
    }

    transition: 'opacity' 0.5s;



    .imagesGridImageWrapper {
        grid-column: span 3;
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 1fr)); //minmax(0, 1fr)
        overflow: hidden;

        &.MuiPaper-root {
            margin-left: 0.5rem;
            margin-right: 0.5rem;
            padding: 1rem;
        }

        .imgBlockOuter {
            grid-row: 1;
            grid-column: 1/ span 4;
            display: flex;
            justify-content: center;

            .imgBlockInner {

                max-width: 100%;
                margin-bottom: 1rem;
                text-align: center;
                height: fit-content;
                width: fit-content;

                img {
                    max-width: 100%;
                    max-height: 350px;
                    margin-bottom: 1rem;
                }
            }

        }


        .descriptionEditField {
            grid-column: 1/ span 4;
            display: flex;
            flex-direction: column;
            justify-content: flex-end;

            .autoField-input-wrapper.input-wrapper--description {
                grid-column: span 12;
            }

            .generalActionFormWrapper {
                overflow-y: unset;
                overflow-x: unset;
            }
        }


        .description {
            grid-row: 2;
            grid-column: 1/ span 4;
            display: flex;
            align-self: flex-end;
            color: #14487c;
            font-size: 1rem;
            background: #f2f6fa;
            padding: 0.5rem;
            width: 120%;
            margin-left: -10%;
            padding-left: 12%;
            padding-right: 12%;
        }

        .imageActions {
            display: flex;
            justify-content: flex-end;

            .actionItem {
                margin-left: 0.5rem;

                .MuiButtonBase-root {
                    color: $dynamic-blue;
                }

            }
        }
    }
}

.smallImageConfirmation {
    text-align: center;
    padding: 1rem;
}