@import '../../../../../styles/app/common/variables.scss';

#contractSubHeading {
  background: $white;
  z-index: 2;

  .MuiInput-input {
    font-size: 14px;
  }
}

.dx-datagrid-rowsview {
  z-index: 1;
}

.menuIconWrapper {
  position: absolute;
  margin-left: -51px;

  .MuiButtonBase-root {
    color: $blue-dark;
    padding: 3px;

    .MuiIconButton-label {
      .MuiSvgIcon-root {
        font-size: 1.8rem;
      }
    }
  }
}