@import '../../../../styles/app/common/variables.scss';



.autoField-input-wrapper {

  display: flex;

  &.printable {
    display: inline;
    border: solid #edf1f2 1px;
    padding: 0.5rem;

    span.helpText {
      font-size: 0.9rem;
      color: grey;
      font-style: italic;
    }

    span.label {
      color: grey;
      font-style: italic;
    }
  }

  &.hidden {
    display: none;
  }



  .MuiAutocomplete-root {
    height: 100%;
  }

  .MuiFormControl-root {
    height: 100%;

    &.full-height {
      .MuiInputBase-root {
        height: 100%;
      }
    }

    .MuiAutocomplete-input {
      // ADJUST TO FIX BATCH CREATE, BUT KEEP IN MORE SPECIFIC CONTEXT TO FIX SUPPLIER CONTRACT DETAIL FORM
      height: 100%;
    }
  }

  .MuiInput-underline:before {
    opacity: 0.3;
  }

  .dx-texteditor.dx-editor-underlined:after {
    opacity: 0.3;
    bottom: 2px;
  }

  &.valueDiffers {

    //   .MuiInput-input {
    //     font-size: 14px;
    //     background-color: $red;
    //   }
    .MuiInput-underline:before {
      border-bottom: solid 2px $green;
      border-bottom-style: solid;
      opacity: 1;
    }

    .MuiInput-underline.MuiInput-disabled:before {
      border-bottom-style: solid;
    }

    .dx-texteditor.dx-editor-underlined:after {
      border-bottom: solid 2px $green;
    }
  }

  .dx-overlay-content.dx-invalid-message-content {
    z-index: 5000;
    background: red;
    color: white;
    padding: 1rem;
  }

  .dx-overlay-wrapper.dx-invalid-message {
    z-index: 5000 !important;
  }

}