#NotificationIconsContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;

    img.disabled {
        opacity: 0.25;
    }

    img.notify-due-icon.disabled {
        opacity: 0.4; // it's orange so it needs to be a bit stronger than the rest
    }

    .fas.fa-file-import.disabled {
        opacity: 0.3;
    }
}