@import '../../../../../styles/app/common/variables';

#visibilityControlsTab {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
  min-width: 175px;
  border-right: $blue-dark 1px solid;
  padding-right: 1rem;

  .MuiButton-textPrimary, .MuiButton-outlinedPrimary {
    color: $blue-dark;
    padding: 5px 15px;
    height: 38px;
  }

  .MuiButton-outlinedPrimary {
    border: 1px solid $blue-dark-light;
  }

  .visibility-control {
    &__icon {
      max-height: 25px;
      line-height: 25px;
      padding-right: .5rem;
    }

    &__button {
      margin-bottom: .5rem;
      justify-content: flex-start;
    }
  }
}
