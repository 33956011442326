@import "../../../../styles/app/common/variables";


.admin-panel-body {
  overflow-x: hidden;
  height: 100%;

  .record-container .field {
    color: #fff;
  }

  .image {
    min-height: 160px;
    
    & > img {
      height: auto;
      max-width: 150px;
    }
  }

  // All sublists
  #sublist-card {
    #highlight, .label {
      color: $white;
      padding-right: 0.5rem;
      font-size: 0.775rem;
      display: inline-block;      
    }
    .label {
      color: $gray-med-dark;
    }
  }
  
  .admin-panel-child-tabs-header {
    em {
      margin-right: 1.5rem;
    }
  }
  
  .admin-panel-tabs-actions + div {
    margin-right: -8px;
  }
  
  
  .sublist-unlink {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    
    .fa-unlink {
      color: $white;
    }
  }
}

.child-scroll {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;  
}
