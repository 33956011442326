@import './src/styles/app/common/variables.scss';
//general

.autoFieldWrapper .autoField-input-wrapper {
    height: 100%;

    .MuiFormControl-root,
    .MuiInputBase-root {
        height: 100%;
    }

    .MuiFormButtonBase-root {
        width: 100%;
    }

}

#snapshotDocumentsView {

    gap: 10px;
    padding: 10px;

    .filepond--wrapper {
        height: 100%;
        max-height: 100%;

        .filepond--root {
            margin: 0;
            padding: 0;
            height: 100%;
            max-height: 100%;

            .filepond--drop-label {
                max-height: 100%;
                height: 100%;
            }
        }

        grid-row-start: 1;
        grid-row-end: 4;
        grid-column-start: 9;
        grid-column-end: 13;
        padding: 0;
        margin: 0;

    }

    // .MuiInputBase-root {
    //     height: 100%;
    // }

    .input-wrapper--snapshot {
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 1;
        grid-column-end: 3;
    }

    .input-wrapper--snapshot_document_type {
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 3;
        grid-column-end: 7;
    }

    .input-wrapper--internal_access_only {
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 7;
        grid-column-end: 9;
    }

    .input-wrapper--description {
        grid-column-start: 1;
        grid-column-end: 9;
        grid-row-start: 2;
        grid-row-end: 4;
    }


}

#snapshotAppendicesView {

    gap: 10px;
    padding: 10px;

    .filepond--wrapper {
        height: 100%;
        max-height: 100%;

        .filepond--root {
            margin: 0;
            padding: 0;
            height: 100px;
            max-height: 100%;

            .filepond--drop-label {
                max-height: 100%;
                height: 100%;
            }
        }

        grid-row-start: 2;
        grid-row-end: 2;
        grid-column-start: 1;
        grid-column-end: 13;
        padding: 0;
        margin: 0;

    }

    .input-wrapper--description {
        grid-row-start: 1;
        grid-row-end: 1;
        grid-column-start: 1;
        grid-column-end: 13;
    }

}

#DetailDocumentsUploader {

    gap: 10px;
    padding: 10px;

    .filepond--wrapper {
        height: 100%;
        max-height: 100%;

        .filepond--root {
            margin: 0;
            padding: 0;
            height: 100px;
            max-height: 100%;

            .filepond--drop-label {
                max-height: 100%;
                height: 100%;
            }
        }

        grid-row-start: 3;
        grid-row-end: 3;
        grid-column-start: 1;
        grid-column-end: 13;
        padding: 0;
        margin: 0;

    }

    .input-wrapper--visit_document_category,
    .input-wrapper--h_a_s_supporting_document_type {
        grid-row-start: 1;
        grid-row-end: 1;
        grid-column-start: 1;
        grid-column-end: 9;
    }

    .input-wrapper--internal_access_only {
        grid-row-start: 1;
        grid-row-end: 1;
        grid-column-start: 9;
        grid-column-end: 13;
    }

    .input-wrapper--notes {
        grid-row-start: 2;
        grid-row-end: 2;
        grid-column-start: 1;
        grid-column-end: 13;
    }

}

#VisitDetailDocumentsUploader {

    gap: 10px;
    padding: 10px;

    .filepond--wrapper {
        height: 100%;
        max-height: 100%;

        .filepond--root {
            margin: 0;
            padding: 0;
            height: 100px;
            max-height: 100%;

            .filepond--drop-label {
                max-height: 100%;
                height: 100%;
            }
        }

        grid-row-start: 4;
        grid-row-end: 4;
        grid-column-start: 1;
        grid-column-end: 13;
        padding: 0;
        margin: 0;

    }

    .input-wrapper--visit_document_category {
        grid-row-start: 1;
        grid-row-end: 1;
        grid-column-start: 1;
        grid-column-end: 9;
    }

    .input-wrapper--document_ref {
        grid-row-start: 1;
        grid-row-end: 1;
        grid-column-start: 9;
        grid-column-end: 13;
    }

    .input-wrapper--permit {
        grid-row-start: 2;
        grid-row-end: 2;
        grid-column-start: 1;
        grid-column-end: 7;
    }

    .input-wrapper--internal_access_only {
        grid-row-start: 2;
        grid-row-end: 2;
        grid-column-start: 7;
        grid-column-end: 13;
    }

    .input-wrapper--notes {
        grid-row-start: 3;
        grid-row-end: 3;
        grid-column-start: 1;
        grid-column-end: 13;
    }





}

.typed-visit-detail-documents-uploader {
    grid-row-start: 2;
    grid-row-end: 2;
    grid-column: span 12;
    gap: 10px;
    padding: 10px;

    .filepond--wrapper {
        height: 100%;
        max-height: 100%;

        .filepond--root {
            margin: 0;
            padding: 0;
            height: 100px;
            max-height: 100%;

            .filepond--drop-label {
                max-height: 100%;
                height: 100%;
            }
        }

        grid-row-start: 2;
        grid-row-end: 2;
        grid-column-start: 1;
        grid-column-end: 13;
        padding: 0;
        margin: 0;

    }

    .input-wrapper--visit_document_category {
        grid-row-start: 1;
        grid-row-end: 1;
        grid-column-start: 1;
        grid-column-end: 7;
    }

    .input-wrapper--document_ref {
        grid-row-start: 1;
        grid-row-end: 1;
        grid-column-start: 7;
        grid-column-end: 13;
    }

    .input-wrapper--notes {
        display: none;
    }

}

.permit-support-documents-group-wrapper {
    grid-row: 3;
    grid-column: span 12;
    font-size: 1rem;
    background-color: $gray-lightest;
    padding: 1rem;
    color: rgba(0, 0, 0, 0.54);
}

.permit-support-documents-group-flex-wrapper {
    padding: 1rem 0;
    display: flex;
    justify-content: flex-start;
    color: rgba(0, 0, 0, 0.87);
}

#RiddorReportUploader {

    gap: 10px;
    padding: 10px;

    .filepond--wrapper {
        height: 100%;
        max-height: 100%;

        .filepond--root {
            margin: 0;
            padding: 0;
            height: 100px;
            max-height: 100%;

            .filepond--drop-label {
                max-height: 100%;
                height: 100%;
            }
        }

        grid-row-start: 3;
        grid-row-end: 3;
        grid-column-start: 1;
        grid-column-end: 13;
        padding: 0;
        margin: 0;

    }

    .input-wrapper--reference {
        grid-row-start: 1;
        grid-row-end: 1;
        grid-column-start: 1;
        grid-column-end: 13;
    }

}

h3.riddor-upload-title {
    font-size: 1.2rem;
    padding: 1rem 0 1rem 0;
    width: 100%;
    text-align: center;
}

.showRiddorNotesLinkWrapper {
    width: 100%;
    display: flex;
    justify-content: center;

    a.showRiddorNotesLink {
        width: 100%;
        text-align: center;
        color: $dynamic-blue !important;
        cursor: pointer;
    }
}


#HASStatutoryDocumentUploader {

    gap: 20px 10px;

    padding: 10px;

    .filepond--wrapper {
        height: 100%;
        max-height: 100%;

        .filepond--root {
            margin: 0;
            padding: 0;
            height: 100px;
            max-height: 100%;

            .filepond--drop-label {
                max-height: 100%;
                height: 100%;
            }
        }

        grid-row-start: 4;
        grid-row-end: 4;
        grid-column-start: 1;
        grid-column-end: 13;
        padding: 0;
        margin: 0;

    }

    .input-wrapper--issue_number {
        grid-row-start: 1;
        grid-row-end: 1;
        grid-column-start: 1;
        grid-column-end: 2;
    }

    #autoField-issue_number.MuiOutlinedInput-input {
        padding: 25px 14px 15px 14px;
    }

    .input-wrapper--notes {
        grid-row-start: 2;
        grid-row-end: 2;
        grid-column-start: 1;
        grid-column-end: 13;
    }

    .input-wrapper--notify_account_managers {
        grid-row-start: 3;
        grid-row-end: 3;
        grid-column-start: 1;
        grid-column-end: 3;

        .MuiTypography-root {
            font-size: 0.9rem;
            margin-right: 5px;
        }

        .MuiFormControlLabel-labelPlacementStart {
            margin-left: 0;
            white-space: nowrap;
        }

    }

    .input-wrapper--notify_site_managers {
        grid-row-start: 3;
        grid-row-end: 3;
        grid-column-start: 4;
        grid-column-end: 6;

        .MuiTypography-root {
            font-size: 0.9rem;
            margin-right: 5px;
        }

        .MuiFormControlLabel-labelPlacementStart {
            margin-left: 0;
            white-space: nowrap;
        }
    }

    .input-wrapper--notify_business_unit_heads {
        grid-row-start: 3;
        grid-row-end: 3;
        grid-column-start: 7;
        grid-column-end: 9;

        .MuiTypography-root {
            font-size: 0.9rem;
            margin-right: 5px;
        }

        .MuiFormControlLabel-labelPlacementStart {
            margin-left: 0;
            white-space: nowrap;
        }
    }

    .input-wrapper--notify_all_key_roles {
        grid-row-start: 3;
        grid-row-end: 3;
        grid-column-start: 10;
        grid-column-end: 12;

        .MuiTypography-root {
            font-size: 0.9rem;
            margin-right: 5px;
        }

        .MuiFormControlLabel-labelPlacementStart {
            margin-left: 0;
            white-space: nowrap;
        }
    }



}

#HASEventsDocumentUploader,
#HASIncidentDocumentUploader {

    gap: 20px 10px;
    padding: 10px;
    padding-top: 25px;

    .MuiInputLabel-formControl {
        top: -12px;
    }

    .filepond--wrapper {
        height: 100%;
        max-height: 100%;

        .filepond--root {
            margin: 0;
            padding: 0;
            height: 100px;
            max-height: 100%;

            .filepond--drop-label {
                max-height: 100%;
                height: 100%;
            }
        }

        grid-row-start: 2;
        grid-row-end: 2;
        grid-column-start: 1;
        grid-column-end: 13;
        padding: 0;
        margin: 0;

    }

    .input-wrapper--matter_for_discussion_document_type {
        grid-row-start: 1;
        grid-row-end: 1;
        grid-column-start: 1;
        grid-column-end: 3;
    }

    .input-wrapper--show_with_event_log {
        grid-row-start: 3;
        grid-row-end: 3;
        grid-column-start: 1;
        grid-column-end: 3;
    }

    .input-wrapper--h_a_s_accident_document_type,
    .input-wrapper--h_a_s_near_miss_document_type {
        grid-row-start: 1;
        grid-row-end: 1;
        grid-column-start: 1;
        grid-column-end: 3;
    }

    .input-wrapper--notes {
        grid-row-start: 1;
        grid-row-end: 1;
        grid-column-start: 3;
        grid-column-end: 13;
    }

}

#HASIncidentImagesUploader {

    gap: 20px 10px;
    padding: 10px;
    padding-top: 25px;

    .MuiInputLabel-formControl {
        top: -12px;
    }

    .filepond--wrapper {
        height: 100%;
        max-height: 100%;

        .filepond--root {
            margin: 0;
            padding: 0;
            height: 100px;
            max-height: 100%;

            .filepond--drop-label {
                max-height: 100%;
                height: 100%;
            }
        }

        grid-row-start: 2;
        grid-row-end: 2;
        grid-column-start: 1;
        grid-column-end: 13;
        padding: 0;
        margin: 0;

    }

    .input-wrapper--description {
        grid-row-start: 1;
        grid-row-end: 1;
        grid-column-start: 1;
        grid-column-end: 13;
    }

}