#documentViewModal {
  &.modal-xl {
    max-width: 930px;
  }

  .modal-body {
    padding: 1rem 1rem .35rem 1rem;
  }
}

.react-pdf__Page.ja-document-viewer-page.portrait {
  background-color: grey;
  display: flex;
  justify-content: center;
}