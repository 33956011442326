.notes-wrapper{
    .MuiButtonBase-root {
        position: absolute;
        font-size: 1.4rem;
        padding: 2px;
        z-index: 5000;
        color:#1e88e5;
    }

    .MuiButtonBase-root.undo {
        right: 2.5rem;
    }

    .MuiButtonBase-root.save {
        right: 0.5rem;
    }

    .autoField-input-wrapper.input-wrapper--notes .MuiInput-underline:before {
        display: none;
    }
    
    .autoField-input-wrapper.input-wrapper--notes .MuiInput-underline:after {
        display: none;
    }

    .autoField-input-wrapper.input-wrapper--notes {
        .MuiInputBase-root {
            font-size: 0.8rem;
            margin-top:2rem;
        }
        
    }



    
}

.dx-datagrid-rowsview .dx-master-detail-row:not(.dx-datagrid-edit-form) .dx-master-detail-cell {
    background-color: white;
}

// position: absolute;
// right: 2rem;
// font-size: 1.4rem;
// color: #b1b1b1;
