@import "../../../styles/app/common/variables";

#PrintDataGrid.specialistPlannerViewGrid {

  thead.specialist-planner__head {

    &.reportContext {
      .specialist-planner__columns td {
        background-color: $blue-dark !important;
        color: white;
      }
    }

    .specialist-planner__columns td {
      background-color: $brandGold !important;
    }

    .specialist-planner__legend td {
      background-color: none !important;
    }
  }

  font-size: 12px;
  /*font-family: "Helvetica Neue", "Segoe UI", Helvetica, Verdana, sans-serif;*/
  font-family: 'Jost',
  sans-serif;
  font-weight: 400;
  width: 100%;

  td {
    padding: 7px;
    border-bottom: 1px solid #ddd;

    .flags-wrapper {
      padding: 0rem;
      cursor: default;
    }
  }

  .specialist-flag {
    display: inline-block !important;
    margin-right: 7px !important;
  }

  // // @media screen, print {
  // .specialist-flag.warn {
  //   &:before {
  // content: "";
  // position: relative;
  // width: 15px;
  // height: 1px;
  // bottom: 0;
  // left: 0;
  // border-bottom: 2px solid red;
  // display: block;
  // margin-top: 3px;
  //   }
  // }

  //}


  tr {
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
  }

  thead {
    -webkit-print-color-adjust: exact !important;
    border-top: 1px solid #ddd;
    font-size: 13px;
  }
}

//   @media print {
//     @page {
//       size: A3;
//     }
//   }

// NB This next block is used to scale the specialist planner view for printing.  Seems to work nicely!  It doesn't seem to unduly effect printing when viewing other components
// or effect the report, but worth keeping an eye on.
@media print {
  body {
    zoom: 70%;
  }
}