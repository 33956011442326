@import '../../styles/app/common/variables.scss';

#gridHeader.schedule-items-header {
    width: 70%;
}

#scheduleGridContainerMaintenanceVisits .dx-datagrid-filter-row.dx-row td.dx-editor-cell,
#scheduleGridContainerHASChecks .dx-datagrid-filter-row.dx-row td.dx-editor-cell {
    height: 50px;
}

.exceptions-switch {
    height: 0;
    margin: 0;
    padding: 0;
    margin-top: 1rem;
    color: $dynamic-blue;
}

.scheduleHeaderSettingsGroupWrapper {
    display: flex;
    flex-direction: column;
}