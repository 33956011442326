#urgentListsContainer {
  position: relative;
  height: 90vh;
  width: 90vw;
  top: 3vh;
  left: 5vw;
  right:5vw;
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  grid-template-rows: repeat(2, 2fr);
  align-items: center;
  justify-content: center;
}

.quadrant {
  .collapser {
    display: None;
  }
  &.expanded {
    .collapser {
      display:block;
    }
  }
 .collapser {
  position: absolute;
  left: 0.5em;
  top: 0.5em;
  background-color: #ffffff;
  border-radius: 50%;
  padding: 0;
  

 }

  &.fade-right::after {
    position: absolute;
    bottom: 0;  
    right: 0;
    height: 100%;
    width: 50%;
    content: "";
    background: linear-gradient(to left,
      rgba(255,255,255, 0.65) 1%, 
      rgba(255,255,255, 0) 99%
    );
    pointer-events: none; /* so the text is still selectable */
  }
  
  position: absolute;
  border-radius: 7px;
  
  overflow: hidden;
  transition: height 0.5s, width 0.5s;
  &.expand {z-index: 1;}
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;

}
.watermark {
  position: absolute;
  top: 61vh;
  right: -2vw;
  opacity: 0.35;
  font-size: 2em;
  width: 100%;
  text-align: center;
  
}

.quadrant-wrapper {
  position: relative;
  height: 90vh;
  width: 90vw;
  top: 0vh;
  left: 0vw;  
}

.quadrant:hover table {
  opacity: 1;
}
#firstQuadrant {
  top: 0;
  left: 0;
  grid-row: 1;
  grid-column-start: 1;
  grid-column-end: 2;
  background: linear-gradient(112.1deg, rgb(8, 72, 124) 11.2%, rgb(43, 110, 164) 70.2%);
  
 
}
#secondQuadrant {
  top: 0;
  right: 0;
  grid-row: 1;
  grid-column-start: 2;
  grid-column-end: 3;
  background: linear-gradient(109.6deg, rgb(252, 200, 50) 11.2%, rgb(250, 223, 141) 100.2%);
  
}
#secondQuadrant:hover {
  top: 0;
  right: 0;
}
#thirdQuadrant {

  bottom: 0;
  left: 0;
  grid-row: 2;
  grid-column-start: 1;
  grid-column-end: 2;
  background: linear-gradient(109.6deg, rgb(252, 200, 50) 11.2%, rgb(250, 223, 141) 100.2%);
}
#fourthQuadrant {
  bottom: 0;
  right: 0;
  grid-row: 2;
  grid-column-start: 2;
  grid-column-end: 3;
  background: linear-gradient(112.1deg, rgb(8, 72, 124) 11.4%, rgb(43, 110, 164) 70.2%);  
}

