@import '../../../../styles/app/common/variables.scss';
.showUnfilteredWrapper {
    width: 100%;
    text-align: center;
    padding:1rem;
    display: block;
    
}
.financialsGrid {
    .dx-datagrid-content {
        //max-height: 750px;
        overflow: auto;
    }
}


#awDetailModal {
    
    &.fullWidth {
        width: 92%;
        min-width: 92%;
    }
    .awHeaderDetail {
        width: 33%;
        display: inline-block;
        color: white;
        button.MuiIconButton-root {
            color:white;
        }
    }
    .modal-title { 
        width: 100%;
        text-align: center;
    }
    .closeButtonWrapper {
        text-align: right;
    }
}

