@import '../../../../../styles/app/common/variables.scss';

#closeBatchVisitsFormButtonWrapper {
    height: 0; // so as not to push the rest of the form down
    width: 100%;
    display: flex;
    justify-content: flex-end;

    .MuiButtonBase-root {
        margin-right: 0.5rem;
        //top: 0.5rem;
        height: 3rem; // so as not to squash the background to the button (seen on hover)
    }
}

.autoFieldWrapper .autoField-input-wrapper {
    height: 100%;

    .MuiFormControl-root,
    .MuiInputBase-root {
        height: 100%;
    }

    .MuiFormButtonBase-root {
        width: 100%;
    }

    .MuiInput-underline:before {
        opacity: 0.3;
    }

}

.batchCreateVisitsForm {

    gap: 20px;
    padding: 10px;

    .input-wrapper--from {
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 1;
        grid-column-end: 5;
    }

    .input-wrapper--to {
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 5;
        grid-column-end: 9;
    }

    .input-wrapper--interval {
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 9;
        grid-column-end: 11;
    }

    .input-wrapper--frequency {
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 11;
        grid-column-end: 14;
    }

    .input-wrapper--exclude_start {
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 14;
        grid-column-end: 18;
    }

    .input-wrapper--weekdays_only {
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 17;
        grid-column-end: 22;
    }

    .input-wrapper--avoid_bank_holidays {
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 20;
        grid-column-end: 24;
    }

    .generateVisitsPreviewButtonWrapper {
        grid-row-start: 2;
        grid-row-end: 3;
        grid-column-start: 1;
        grid-column-end: 24;
    }

    .generateVisitsPreviewButtonWrapper {
        .MuiButtonBase-root {
            width: 100%;
            background-color: $green;
        }
    }


}

.batchVisitsCreateFormOuterWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

#scheduleListGridContainer {
    max-width: "100%" !important;
}