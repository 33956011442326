@import '../../styles/app/common/variables.scss';

#HASCentreSubHeading {
    background: $white;
    z-index: 2;

    .MuiInput-input {
        font-size: 14px;
    }

    .generalActionFormWrapper {
        margin-top: 0; //overidding general value
    }
}

.menuIconWrapper {
    position: absolute;
    margin-left: -51px;

    .MuiButtonBase-root {
        color: $blue-dark;
        padding: 3px;

        .MuiIconButton-label {
            .MuiSvgIcon-root {
                font-size: 1.8rem;
            }
        }
    }
}

.HASCentreWrapper {
    margin-top: 5rem;
}

.input-wrapper--startDate {
    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 7;
    grid-column-end: 10;

    &.autoField-input-wrapper {
        display: inline-block;
    }
}

.input-wrapper--endDate {
    grid-row-start: 1;
    grid-row-end: 1;
    grid-column-start: 10;
    grid-column-end: 13;

    &.autoField-input-wrapper {
        display: inline-block;
    }
}