@import "../../../../styles/app/common/variables.scss";

#profileForm {
  h2, label {
    color: black; 
  }

  label {
    margin-bottom: 0.25rem;
    color: $gray-darker;
  }
}