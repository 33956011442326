.print-page-wrapper {
  &__new-after {
    page-break-after: always;
  }

  &__new-before {
    page-break-before: always;
  }

  &__keep-together {
    page-break-inside: avoid;
  }
}

.no-break-inside {
  page-break-inside: avoid !important;
  break-inside: avoid !important;
}
