#adminPanel {
  position: fixed;
  height: 95vh;
  top: 10px;
  left: 10px;
  min-width: 605px;
  //width: 47.5%;
  width:95%;
  min-height: 400px;
  z-index: 3000;
  background-color: white;
  border-radius: 0.375rem;
  overflow: hidden;
  border: solid 1px #e0e0e0;

  @media (max-width: 1024px) {
    width: unset;
    right: 30px;
  }

  .admin-container {
    display: flex;
    flex-flow: column;
    height: 100%;

  }

  .contractDetailViewPrimaryRolesWrapper {
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;
    justify-content: center;
    color: rgba(0,0,0,.54);
    span {
      padding-top:1rem;
      padding-bottom:1rem;
      display: inline-block;
    }
  }
  .toolbarTitle {
    font-size: 1rem;
    font-weight: 400;
  }
}
