@import "../../../../../styles/app/common/variables.scss";

.my-first-cell {
  background-color: $table-background-highlight;
  color: black;
}

.my-last-cell {
  background-color: $table-background-highlight;
}

/* css ammendment start */

// .my-first-cell {
//   background-color: $blue-dark !important;
//   color:white !important;
// }

// .my-last-cell {
//   background-color: $yellow !important;
//   color: black !important;
// }

// .dx-row.dx-header-row {
//   background-color: $blue-dark !important;
//   color: white !important;
// }

/* css ammendment end */

.buttons {
  text-align: right;
  border: 1px solid $table-border-highlight;
  border-bottom: none;
}

#maintenance-grid {
  .dx-texteditor-input {
    text-align: right;
  }
}
