#PrintDataGrid {
    font-size: 12px;
    /*font-family: "Helvetica Neue", "Segoe UI", Helvetica, Verdana, sans-serif;*/
    font-family: 'Jost', sans-serif;
    font-weight: 400;
    width: 100%;
    
    td {
      padding: 7px;
      border-bottom: 1px solid #ddd;
    }

    tr {
      border-left: 1px solid #ddd; 
      border-right: 1px solid #ddd; 
    }

    thead {
      border-top: 1px solid #ddd; 
      font-size: 13px;
    }
}
