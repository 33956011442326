@import '../../../../styles/app/common/variables.scss';

.main-left-sidebar {
  // &--label-container {
  // }

  .MuiDrawer-paperAnchorDockedLeft {
    border-right: none;
  }

  // &.closed {
  //   .left-sidebar-upper-image {
  //     img {
  //       visibility: hidden;
  //       display: none;
  //     }
  //   }
  // }

  .sidebar-nav {
    color: $gray-dark;
    transition: margin-left 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    padding-top: 0.725em;
  }

  .sidebar-nav.collapsed {
    margin-left: -17px;
  }

  .sideBarToggleWrapper {
    display: flex;

    &.collapsed {
      justify-content: center
    }

    ;

    &.expanded {
      justify-content: flex-end
    }

    ;

    &.expanded.opener {
      display: none
    }

    ;

    .sideBarToggle.MuiButtonBase-root {
      width: 30px;
      height: 30px;
      padding: 0px;
      transition: margin-right 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    }

    .sideBarToggle.MuiButtonBase-root.expanded {
      margin-right: 4px;
    }
  }



  &--icon-label {
    display: inline-block;
  }

  &--icon {
    left: 15px;
    display: inline-flex;
    justify-content: center;
    width: 28px;
    padding-right: 10px;
    font-size: 1rem;
  }

  &--label {
    padding-left: 1rem;
    left: 40px;
    display: none;
  }
}

.aside-spacer {
  @media print {
    margin-left: 0px !important;
  }
}