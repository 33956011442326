@import 'src/styles/app/common/variables.scss';

.workPermitForm {
    @media screen {
        position: relative;
        height: 80vh;
        overflow: scroll;

        .GAFormButtons.Top {
            position: sticky;
            top: 0;
            background-color: white;
            z-index: 4000;
            /* border-bottom: 1px solid grey; */
            -webkit-box-shadow: 0 4px 6px -6px #222;
            -moz-box-shadow: 0 4px 6px -6px #222;
            box-shadow: 0 4px 6px -6px #222;
        }
    }

}

.generalActionFormWrapper {

    .MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child {
        padding: 12px 0;
    }

    .generalActionFormGrid.workPermitsForm {
        #autoField-finish_time {
            margin-bottom: 1rem;
        }

        .autoField-input-wrapper {
            .input-wrapper--finish_time {
                margin-bottom: 2rem;
            }

        }

        .field-group-wrapper {
            h3 {
                margin-bottom: 2rem;
            }

            // .autoField-input-wrapper {
            //     margin-bottom: 2rem;
            // }
        }

        .autoField-input-wrapper {
            .mat-field-label {
                font-size: 1rem;
            }

            .MuiInputLabel-shrink {
                transform: none;
            }

            .MuiFormLabel-root {
                color: black;
                font-size: 1rem;
            }

            text-transform: initial;
        }

        .permitTypePicker {
            width: 25%;
            padding: 0 0 3rem 0;
        }

        .field-group-wrapper {

            &.authorisation {

                .JBSAuthorisedPerson {
                    background-color: $gray-lightest;
                    padding: 1rem;
                    grid-column: span 12;
                    grid-row: 2;
                    margin: 0;
                    margin-bottom: 1rem;

                    .input-wrapper--jbs_authorised_person {
                        grid-column: span 6;
                        grid-row: 2
                    }

                    .input-wrapper--jbs_authorised_person_first_name {
                        grid-column: span 6;
                        grid-row: 3
                    }

                    .input-wrapper--jbs_authorised_person_last_name {
                        grid-column: span 6;
                        grid-row: 3
                    }

                    .input-wrapper--jbs_authorised_person_position {
                        grid-column: span 6;
                        grid-row: 4
                    }

                    .input-wrapper--jbs_authorised_person_email {
                        grid-column: span 6;
                        grid-row: 4
                    }
                }

                .NominatedPerson {
                    background-color: $gray-lightest;
                    padding: 1rem;
                    grid-column: span 12;
                    grid-row: 3;
                    margin: 0;
                    margin-bottom: 1rem;

                    .input-wrapper--assessment_confirmation {
                        grid-column: span 6;
                        grid-row: 3
                    }

                    .input-wrapper--high_risk {
                        grid-column: span 6;
                        grid-row: 3
                    }

                    .input-wrapper--nominated_person {
                        grid-column: span 6;
                        grid-row: 4
                    }

                    .input-wrapper--nominated_person_first_name {
                        grid-column: span 6;
                        grid-row: 5
                    }

                    .input-wrapper--nominated_person_last_name {
                        grid-column: span 6;
                        grid-row: 5
                    }

                    .input-wrapper--nominated_person_position {
                        grid-column: span 6;
                        grid-row: 6
                    }

                    .input-wrapper--nominated_person_email {
                        grid-column: span 6;
                        grid-row: 6
                    }

                    .input-wrapper--nominated_person_contact_number {
                        grid-column: span 6;
                        grid-row: 7
                    }
                }

                .Supervisor {
                    background-color: $gray-lightest;
                    padding: 1rem;
                    grid-column: span 12;
                    grid-row: 4;
                    margin: 0;
                    margin-bottom: 1rem;

                    .input-wrapper--supervisor_first_name {
                        grid-column: span 6;
                        grid-row: 2
                    }

                    .input-wrapper--supervisor_last_name {
                        grid-column: span 6;
                        grid-row: 2
                    }

                    .input-wrapper--supervisor_position {
                        grid-column: span 6;
                        grid-row: 3
                    }

                    .input-wrapper--supervisor_email {
                        grid-column: span 6;
                        grid-row: 3
                    }

                    .input-wrapper--supervisor_contact_number {
                        grid-column: span 6;
                        grid-row: 4
                    }
                }

                .input-wrapper--start_time {
                    grid-column: span 3;
                    grid-row: 5;
                }

                .input-wrapper--finish_time {
                    grid-column: span 3;
                    grid-row: 5;
                }

                .input-wrapper--description {
                    grid-column: span 12;
                    grid-row: 6
                }


                &.parent {
                    display: grid; // blocks that have children automatically get .parent applied and are currently rendered as 'flex' by other css
                    // unless overridden (to prevent the child blocks being compressed into one column).
                }

                // .clientPermission {
                //     background-color: $gray-lightest;
                //     padding: 1rem;
                //     grid-column: span 12;
                //     grid-row: 6;
                //     margin: 0;
                //     margin-bottom: 1rem;

                //     &.field-group-wrapper {
                //         h3 {
                //             margin-bottom: 0;
                //             font-size: 1rem;
                //         }

                //         .autoField-input-wrapper {
                //             margin-bottom: 0;
                //         }
                //     }

                //     .input-wrapper--client_permission_granted {
                //         grid-column: span 12;
                //         grid-row: 2;
                //         margin-top: 0;
                //     }
                // }

                .input-wrapper--client_permission_granted {
                    grid-column: span 12;
                    grid-row: 7;
                }


                .input-wrapper--fire_detection_systems_will_be_affected {
                    grid-column: span 12;
                    grid-row: 8
                }

                .input-wrapper--suppression_systems_will_be_affected {
                    grid-column: span 12;
                    grid-row: 9
                }

                .input-wrapper--isolation_comments {
                    grid-column: span 12;
                    grid-row: 10
                }
            }

            &.safeSystemsOfWork {

                .typed-visit-detail-documents-uploader {
                    grid-column: span 12;
                    grid-row: 2;
                }

                .permit-support-documents-group-wrapper {
                    grid-column: span 12;
                    grid-row: 3;
                }

                .hot-work-isolation-systems {
                    grid-row: 4;
                    grid-column: span 12;
                    display: none;
                    padding-bottom: 2rem;
                    padding-top: 2rem;
                }

                .input-wrapper--rams_reviewed {
                    grid-column: span 12;
                    grid-row: 5;
                }

                .input-wrapper--workers_informed_of_local_hazards {
                    grid-column: span 12;
                    grid-row: 6;
                }

                .input-wrapper--relevant_ppe_checked {
                    grid-column: span 12;
                    grid-row: 7;
                }

                .input-wrapper--communication_systems {
                    grid-column: span 12;
                    grid-row: 8;
                }

                .input-wrapper--communication_systems_details {
                    grid-column: span 12;
                    grid-row: 9;
                }

            }

            &.documentation {

                .input-wrapper--attendance_register_signed {
                    grid-column: span 6;
                    grid-row: 2
                }

                .input-wrapper--induction_given {
                    grid-column: span 6;
                    grid-row: 3
                }

                .input-wrapper--date_induction_received {
                    grid-column: span 6;
                    grid-row: 3;
                }


                // .keysIssued {
                //     background-color: $gray-lightest;
                //     padding: 1rem;
                //     grid-column: span 12;
                //     grid-row: 3;
                //     margin: 0;
                //     margin-bottom: 1rem;

                //     h3 {
                //         margin-bottom: 0;
                //         font-size: 1rem;
                //     }

                //     .input-wrapper--keys_issued {
                //         grid-column: span 6;
                //         grid-row: 2
                //     }

                //     .input-wrapper--date_keys_issued {
                //         grid-column: span 6;
                //         grid-row: 3
                //     }

                //     .input-wrapper--key_recipient_name {
                //         grid-column: span 6;
                //         grid-row: 3
                //     }

                // }
            }

            &.controlMeasures {

                .input-wrapper--combustibles_removed {
                    grid-column: span 12;
                    grid-row: 2
                }

                .input-wrapper--combustibles_protected {
                    grid-column: span 12;
                    grid-row: 3
                }

                .input-wrapper--area_protected_from_fire {
                    grid-column: span 12;
                    grid-row: 4
                }

                .input-wrapper--firefighting_equipment_available {
                    grid-column: span 12;
                    grid-row: 5
                }

                .input-wrapper--fire_watch_to_be_maintained {
                    grid-column: span 12;
                    grid-row: 6
                }
            }

            &.primaryHazards.HotWorksGroup {
                .input-wrapper--fire_alarm_can_be_sounded {
                    grid-column: span 12;
                    grid-row: 2
                }

                .input-wrapper--surfaces_protected {
                    grid-column: span 12;
                    grid-row: 3
                }

                .input-wrapper--vessels_or_lines_protected_and_flammables_free {
                    grid-column: span 12;
                    grid-row: 4
                }

                .input-wrapper--area_to_be_wetted_or_fire_blankets_used {
                    grid-column: span 12;
                    grid-row: 5
                }

                .input-wrapper--ppe_required {
                    grid-column: span 12;
                    grid-row: 6
                }

                .input-wrapper--other_precautions {
                    grid-column: span 12;
                    grid-row: 7
                }
            }

            &.safeSystemsOfWork.WorkingAtHeightGroup {
                .input-wrapper--signs_and_barriers {
                    grid-column: span 12;
                    grid-row: 10;
                }

                .input-wrapper--signs_and_barriers_in_place {
                    grid-column: span 12;
                    grid-row: 11;
                }

                .input-wrapper--site_specific_rules_communicated {
                    grid-column: span 12;
                    grid-row: 12;
                }

                .input-wrapper--activities {
                    grid-column: span 12;
                    grid-row: 13;
                }
            }

            &.documentation.HotWorksGroup {

                .input-wrapper--equipment_certification_submitted {
                    grid-column: span 12;
                    grid-row: 4
                }
            }

            &.primaryHazards.WorkingAtHeightGroup {
                .input-wrapper--qualified {
                    grid-column: span 12;
                    grid-row: 2;
                }

                // .input-wrapper--signs_and_barriers {
                //     grid-column: span 12;
                //     grid-row: 3;
                // }

                .input-wrapper--boards_handrails_ladders {
                    grid-column: span 12;
                    grid-row: 4;
                }

                .input-wrapper--scaffolding_and_arrest_systems {
                    grid-column: span 12;
                    grid-row: 5;
                }

                .input-wrapper--scaffolding_inspected_and_tagged {
                    grid-column: span 12;
                    grid-row: 6;
                }

                .input-wrapper--harness_inspected {
                    grid-column: span 12;
                    grid-row: 7;
                }

                .input-wrapper--harness_lanyard_certificate_provided {
                    grid-column: span 12;
                    grid-row: 8;
                }

                .input-wrapper--access_equipment_inspected {
                    grid-column: span 12;
                    grid-row: 9;
                }

                .input-wrapper--weather_conditions {
                    grid-column: span 12;
                    grid-row: 10;
                }

                .input-wrapper--ducts_and_outlets {
                    grid-column: span 12;
                    grid-row: 11;
                }

                .input-wrapper--fume_hazards {
                    grid-column: span 12;
                    grid-row: 12;
                }

                .input-wrapper--falling_objects_risk {
                    grid-column: span 12;
                    grid-row: 13;
                }

                .input-wrapper--equipment_tethered {
                    grid-column: span 12;
                    grid-row: 14;
                }

                .input-wrapper--overhead_services {
                    grid-column: span 12;
                    grid-row: 15;
                }

                .input-wrapper--edge_protection {
                    grid-column: span 12;
                    grid-row: 16;
                }

                .input-wrapper--guard_rails {
                    grid-column: span 12;
                    grid-row: 17;
                }

                .input-wrapper--ppe_required {
                    grid-column: span 12;
                    grid-row: 18;
                }

            }

            &.documentation.LimitationofAccessGroup {

                .input-wrapper--escort_required {
                    grid-column: span 12;
                    grid-row: 4;
                }

                .input-wrapper--supervision_required {
                    grid-column: span 12;
                    grid-row: 5;
                }
            }

            &.safeSystemsOfWork.HotWorksGroup {

                .hot-work-isolation-systems {
                    display: inline-block;
                }

            }



            &.safeSystemsOfWork.LimitationofAccessGroup {

                .input-wrapper--signs_and_barriers {
                    grid-column: span 12;
                    grid-row: 10;
                }

                .input-wrapper--site_specific_rules_communicated {
                    grid-column: span 12;
                    grid-row: 11;
                }

            }

            &.primaryHazards.LimitationofAccessGroup {

                .input-wrapper--access {
                    grid-column: span 12;
                    grid-row: 3;
                }

                .input-wrapper--egress {
                    grid-column: span 12;
                    grid-row: 4;
                }

                .input-wrapper--safe_routes {
                    grid-column: span 12;
                    grid-row: 5;
                }

                .input-wrapper--additional_information {
                    grid-column: span 12;
                    grid-row: 6;
                }

            }

            &.authorisation.ConfinedSpaceGroup {
                .input-wrapper--type_of_confined_space_and_specified_risks {
                    grid-column: span 12;
                    grid-row: 7
                }

            }

            &.safeSystemsOfWork.ConfinedSpaceGroup {

                .input-wrapper--worker_competences_checked {
                    grid-column: span 12;
                    grid-row: 10;
                }

                .PTW-training-courses {
                    grid-row: 11;
                }

                .rescue-roles {
                    grid-row: 12;
                }

                .PTW-training-courses,
                .rescue-roles {
                    grid-column: span 13;
                    margin-bottom: 2rem;
                }

            }

            &.primaryHazards.ConfinedSpaceGroup {

                .input-wrapper--acceptable_access {
                    grid-column: span 12;
                    grid-row: 2;
                }

                .input-wrapper--isolated_from_pipework {
                    grid-column: span 12;
                    grid-row: 3;
                }

                .input-wrapper--space_purged {
                    grid-column: span 12;
                    grid-row: 4;
                }

                .input-wrapper--electrically_and_mechanically_isolated {
                    grid-column: span 12;
                    grid-row: 5;
                }

                .input-wrapper--temperature_maintainable {
                    grid-column: span 12;
                    grid-row: 6;
                }

                .input-wrapper--breathing_apparatus_checked {
                    grid-column: span 12;
                    grid-row: 7;
                }

                .input-wrapper--backup_equipment_checked {
                    grid-column: span 12;
                    grid-row: 8;
                }

                .input-wrapper--emergency_arrangements_in_place {
                    grid-column: span 12;
                    grid-row: 9;
                }

                .input-wrapper--atmospheric_testing_required {
                    grid-column: span 12;
                    grid-row: 10;
                }

                .input-wrapper--respirable_air_assured {
                    grid-column: span 12;
                    grid-row: 11;
                }

                .input-wrapper--atmospheric_testing_unit_info {
                    grid-column: span 8;
                    grid-row: 12;
                }

                .input-wrapper--atmospheric_testing_calibration_date {
                    grid-column: span 4;
                    grid-row: 12;
                    width: 100%;
                }

                .input-wrapper--residual_hazards {
                    grid-column: span 12;
                    grid-row: 13;
                }

            }

        }


        .isolations {
            padding: 2rem;
            background-color: $gray-lightest;

            .preSaveNote {
                font-weight: bold;
            }
        }
    }
}

.signature-progress-form-wrapper {
    width: 100%;
    height: 100vh;
}

.ptwUploaderActionButtons {
    width: 100%;
    display: flex;
    justify-content: space-around;
    padding: 1rem;
}

.ptw-actions-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 1rem;

    .next-signature-link {
        margin-top: 8px;
    }
}

.permit-ref {
    font-size: 1.1rem;
    position: relative;
    float: right;
    height: 0px;
    bottom: -1rem;
}

.ptwInfoListItem {
    margin-top: 1.5rem;
}

.permitCancellationForm {
    .input-wrapper--cancellation_reason {
        grid-column: span 13;
        grid-row: 2;
    }


}

.permit-cancel-close-button {
    position: absolute;
    top: 0;
    right: 0;

    button {
        color: white;
    }
}