header {
    
    @media print {
        display:none !important;
    }
}
.headerWrapper{
    display: flex;
    justify-content: space-between;
    @media print {
        margin-top:1rem;
    }
}
.quoteViewLogoWrapper{
    width:275px;
    margin-right: -27px;
    img {
        width: 100%;
    }
    @media print {
        width:200px;
    }
}
.JBSTradingAddressWrapper{
    text-align: right;
}

#appriseVer {
    @media print {
        display: none !important;
    }
}

#backToAWList {
    margin-bottom: 0.5rem;
}

.AWDetails.paperQuote {
    width: 100%;
}

h1.QuoteTitle {
    font-size: 1.4rem;
    font-weight: 500;
}

table.quote-view-title-properties {
    font-size: 1rem;
}
table.quote-view-title-properties td{
    padding-top:0.5rem;
}

.quote-view-title-label, .emphasised{
    font-weight: 500;
}

.quote-view-title-label{
    padding-right: 2rem;
}

table.quote-view-details {
    margin-top:2rem;
    width: 100%;
}

.quote-view-details tr {
    border:solid black 2px;
}

.quote-view-details tr.no-bottom-border {
    border-bottom: none;
}

.quote-view-details tr.no-upper-border {
    border-top: none;
}

.quote-view-details td {
    padding: 1rem;
}

td.currency {
    text-align: right;
}

.POInputWrapper{
    width: 400px;
    .MuiFormControl-root{
        width: 400px;
    }
}

.poActionsWrapper{
    vertical-align: bottom;
}

.comments-row td{
    height: 300px;
    
    
}

tr.comments-label-row {
    @media print {
        page-break-before:always !important;
        break-before: always !important;
        page-break-inside: avoid;
    }
}

tr.comments-row {
    @media print {
        page-break-inside: avoid !important;
    }
}

tr {
    @media print {
        page-break-inside: avoid !important;
    }
}

tr.po-row {
    @media print {
        page-break-after:always !important;
        break-after: always !important;
    }
}
