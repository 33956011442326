@import 'src/styles/app/common/variables.scss';

.autoFieldWrapper .autoField-input-wrapper {
    height: 100%;

    .MuiFormControl-root,
    .MuiInputBase-root {
        height: 100%;
    }

    .MuiFormButtonBase-root {
        width: 100%;
    }

    .MuiInput-underline:before {
        opacity: 0.3;
    }

}

.supplierContractDetails {

    gap: 20px;
    padding: 10px;

    .OutOfContract input {
        color: red;
    }

    // .MuiInputBase-root {
    //     height: 100%;
    // }

    &.controls {
        background-color: $body-bg;
    }

    #poLookup {
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 1;
        grid-column-end: 4;

        .MuiFormControl-root,
        .MuiInputBase-root {
            width: 100%;
        }

        .MuiFormButtonBase-root {
            width: 100%;
        }
    }

    .MuiButtonBase-root.useValueButton {
        padding: 0;
    }

    .supplierContractDetailsSuggestionsButtonWrapper {
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 4;
        grid-column-end: 7;
        /* align-content: end; */
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
    }

    #addNewSupplierContractButtonWrapper {
        grid-row-start: 1;
        grid-row-end: 1;
        grid-column-start: 11;
        grid-column-end: 13;
        padding-right: 1rem;
    }

    .navigateSupplierContractButtonWrapper {
        display: flex;
        justify-content: center;
        justify-items: center;
        flex-direction: column;

        .MuiButton-root,
        .MuiButtonBase-root,
        .MuiIconButton-root {
            //prevents the background of the button from 'squashing' on hover
            height: 3rem;
            width: 3rem;
        }
    }

    .navigateSupplierContractButtonWrapper.previous {
        grid-row-start: 1;
        grid-row-end: 1;
        grid-column-start: 3;
        grid-column-end: 4;
        font-size: 1.2rem;
    }

    #SupplierContractTitleWrapper {
        grid-row-start: 1;
        grid-row-end: 1;
        grid-column-start: 4;
        grid-column-end: 9;
        display: flex;
        flex-direction: column;
        justify-content: center;
        justify-items: center;
        position: relative;

        &.new {
            grid-column-start: 1;
            grid-column-end: 13;
        }

        #currentContractsInfo {
            font-size: 0.9rem;
            color: grey;
        }

        span {
            text-align: center;
            font-size: 1.2rem;
        }

        span.upper {
            font-size: 1.2rem;
        }

        span.lower {
            font-size: 1rem;
        }
    }

    .navigateSupplierContractButtonWrapper.next {
        grid-row-start: 1;
        grid-row-end: 1;
        grid-column-start: 9;
        grid-column-end: 10;
        font-size: 1.2rem;
    }

    #closeImportInfoButtonWrapper {
        grid-row-start: 1;
        grid-row-end: 2;
        grid-column-start: 11;
        grid-column-end: 13;
        padding-right: 1rem;
    }

    #supplierContractDetailsDeleteButtonWrapper.half-screen {
        grid-column-start: 1;
        grid-column-end: 7;

        .MuiButton-root {
            width: 100%;
        }
    }

    #supplierContractDetailsUpdateButtonWrapper.half-screen {
        grid-column-start: 7;
        grid-column-end: 13;

        .MuiButton-root {
            width: 100%;
        }
    }

    &.supplierServiceDoesNotExist {

        .input-wrapper--equipment_name {
            grid-row-start: 1;
            grid-row-end: 1;
            grid-column-start: 1;
            grid-column-end: 8;
        }

        .input-wrapper--order_number {
            grid-row-start: 1;
            grid-row-end: 1;
            grid-column-start: 8;
            grid-column-end: 13;
        }

        .input-wrapper--supplier_name {
            grid-row-start: 2;
            grid-row-end: 2;
            grid-column-start: 1;
            grid-column-end: 8;
        }

        .input-wrapper--supplier_ref {
            grid-row-start: 2;
            grid-row-end: 2;
            grid-column-start: 8;
            grid-column-end: 13;
        }

        .input-wrapper--contact {
            grid-row-start: 3;
            grid-row-end: 3;
            grid-column-start: 1;
            grid-column-end: 7;
        }

        .input-wrapper--agreed_emergency_response_time {
            grid-row-start: 3;
            grid-row-end: 3;
            grid-column-start: 7;
            grid-column-end: 13;
        }

        .input-wrapper--supplier_telephone_number {
            grid-row-start: 4;
            grid-row-end: 4;
            grid-column-start: 1;
            grid-column-end: 7;
        }

        .input-wrapper--emergency_call_out_contact {
            grid-row-start: 4;
            grid-row-end: 4;
            grid-column-start: 7;
            grid-column-end: 13;
        }

        .input-wrapper--service_details {
            grid-row-start: 5;
            grid-row-end: 5;
            grid-column-start: 1;
            grid-column-end: 13;
        }

        .input-wrapper--notes {
            grid-row-start: 6;
            grid-row-end: 6;
            grid-column-start: 1;
            grid-column-end: 13;
        }

        .input-wrapper--supplier_address {
            grid-row-start: 7;
            grid-row-end: 7;
            grid-column-start: 1;
            grid-column-end: 13;
        }

        .input-wrapper--contract_start_date {
            grid-row-start: 8;
            grid-row-end: 8;
            grid-column-start: 1;
            grid-column-end: 5;
        }

        .input-wrapper--contract_end_date {
            grid-row-start: 8;
            grid-row-end: 8;
            grid-column-start: 5;
            grid-column-end: 9;
        }

        .input-wrapper--number_of_visits {
            grid-row-start: 8;
            grid-row-end: 8;
            grid-column-start: 9;
            grid-column-end: 13;
        }

        #supplierContractDetailsDeleteButtonWrapper {
            grid-row-start: 9;
            grid-row-end: 9;
            grid-column-start: 6;
            grid-column-end: 10;
        }

        #supplierContractDetailsUpdateButtonWrapper {
            grid-row-start: 9;
            grid-row-end: 9;
            grid-column-start: 10;
            grid-column-end: 13;
        }

        #populateBlankFieldsButtonWrapper {
            grid-row-start: 9;
            grid-row-end: 9;
            grid-column-start: 1;
            grid-column-end: 7;
        }

        #overWriteAllButtonWrapper {
            grid-row-start: 9;
            grid-row-end: 9;
            grid-column-start: 7;
            grid-column-end: 13;
        }
    }

    &.supplierServiceExists {
        .input-wrapper--order_number {
            grid-row-start: 1;
            grid-row-end: 1;
            grid-column-start: 1;
            grid-column-end: 4;
        }

        .input-wrapper--supplier_ref {
            grid-row-start: 1;
            grid-row-end: 1;
            grid-column-start: 7;
            grid-column-end: 13;
        }

        .input-wrapper--contact {
            grid-row-start: 2;
            grid-row-end: 2;
            grid-column-start: 1;
            grid-column-end: 7;
        }

        .input-wrapper--agreed_emergency_response_time {
            grid-row-start: 2;
            grid-row-end: 2;
            grid-column-start: 7;
            grid-column-end: 13;
        }

        .input-wrapper--supplier_telephone_number {
            grid-row-start: 3;
            grid-row-end: 3;
            grid-column-start: 1;
            grid-column-end: 7;
        }

        .input-wrapper--emergency_call_out_contact {
            grid-row-start: 3;
            grid-row-end: 3;
            grid-column-start: 7;
            grid-column-end: 13;
        }

        .input-wrapper--service_details {
            grid-row-start: 4;
            grid-row-end: 4;
            grid-column-start: 1;
            grid-column-end: 13;
        }

        .input-wrapper--notes {
            grid-row-start: 5;
            grid-row-end: 5;
            grid-column-start: 1;
            grid-column-end: 13;
        }

        .input-wrapper--supplier_address {
            grid-row-start: 6;
            grid-row-end: 6;
            grid-column-start: 1;
            grid-column-end: 13;
        }

        .input-wrapper--contract_start_date {
            grid-row-start: 7;
            grid-row-end: 7;
            grid-column-start: 1;
            grid-column-end: 5;
        }

        .input-wrapper--contract_end_date {
            grid-row-start: 7;
            grid-row-end: 7;
            grid-column-start: 5;
            grid-column-end: 9;
        }

        .input-wrapper--number_of_visits {
            grid-row-start: 7;
            grid-row-end: 7;
            grid-column-start: 9;
            grid-column-end: 13;
        }

        #supplierContractDetailsDeleteButtonWrapper {
            grid-row-start: 8;
            grid-row-end: 8;
            grid-column-start: 6;
            grid-column-end: 10;
        }

        #supplierContractDetailsUpdateButtonWrapper {
            grid-row-start: 8;
            grid-row-end: 8;
            grid-column-start: 10;
            grid-column-end: 13;
        }

        #populateBlankFieldsButtonWrapper {
            grid-row-start: 8;
            grid-row-end: 8;
            grid-column-start: 1;
            grid-column-end: 7;
        }

        #overWriteAllButtonWrapper {
            grid-row-start: 8;
            grid-row-end: 8;
            grid-column-start: 7;
            grid-column-end: 13;
        }
    }
}





.supplierContractDetailsOuterWrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.SupplierServiceForm {
    .input-wrapper--equipment_name {
        grid-row-start: 1;
        grid-row-end: 1;
        grid-column-start: 1;
        grid-column-end: 8;
    }

    .input-wrapper--supplier_ref {
        grid-row-start: 2;
        grid-row-end: 2;
        grid-column-start: 1;
        grid-column-end: 4;
    }

    .input-wrapper--supplier_name {
        grid-row-start: 2;
        grid-row-end: 2;
        grid-column-start: 4;
        grid-column-end: 13;
    }
}

.supplierServiceFormsWrapper {
    position: relative;
    padding-top: 1rem;
}

.supplierTransferFormWrapper {
    margin-top: 2rem;

    .generalActionFormWrapper {
        margin-top: 0;
    }

    .supplierTransferInfo {
        padding: 2rem;
        padding-top: 3rem;
    }
}

.showTransferControlWrapper {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    text-decoration: underline;
    color: $dynamic-blue;
    font-size: 0.9rem;
    padding: 2rem;
    position: absolute;
    cursor: pointer;
    z-index: 3;

    &.disabled {
        cursor: default;
        opacity: 0.5;
    }
}

.SupplierDataTransferForm {
    .input-wrapper--target_supplier_service {
        grid-column-start: 1;
        grid-column-end: 8;
    }

    z-index: 3;
}

.supplierContractFormsWrapper {
    margin: 1rem;
}

.transferring {
    .supplierContractFormsWrapper {
        opacity: 0.3 !important;
        pointer-events: none !important;
    }
}

.supplierContractDetailsSuggestionsLookup.MuiButton-root,
#supplierContractDetailsUpdateButton.MuiButton-root {
    background-color: $green;

    &:hover {
        background-color: $green-dark;
    }
}

#SupplierServiceTitle {
    margin: 1rem 2rem;
    font-size: 1.2rem;
}

.transferring {
    .supplierServiceDetailsWrapper {
        opacity: 0.5;
        pointer-events: none;
    }
}